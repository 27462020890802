// ==========================================================================
// Recipes
// ==========================================================================

/*----------  Header recette - Fiche recette  ----------*/
.recipe-header {
  position: relative;
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;

  .btn--prev, .btn--next {
    position: absolute;
    top: 50%;
    z-index: 150;
  }
  .btn--prev { left: rem(-45); }
  .btn--next { right: rem(-45); }
}

.recipe-rate {
  margin-bottom: rem(20);

  img { width: rem(20); }
}

.recipe-img { margin: 0 auto rem(35); }

.recipe-infos {
  display: flex;
  height: auto;
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-start;
}

.recipe-instructions {
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  margin: 0 auto;
}
.recipe-instructions__item {
  display: flex;
  margin-bottom: rem(25);

  font-family: $barlow-extralight;
  font-size: rem(18);

  img {
    margin-right: rem(15);
    width: rem(50); height: rem(50);
    flex: 0 0 rem(50);
  }
}

/*----------  Ingrédients recette - Fiche recette  ----------*/
.recipe-ingredients {
  font-size: rem(18);
  line-height: 1.33;
}


// ==========================================================================
// Media Queries
// ==========================================================================

@include media-breakpoint-up(md) {

  .recipe-instructions {
    flex-direction: row;
    margin: rem(15) auto rem(70);
  }

  /*----------  Ingrédients recette  ----------*/
  .recipe-ingredients {
    font-size: rem(20);
    line-height: 1.3;
  }

}

@include media-breakpoint-up(lg) {

  .recipe-header { flex-wrap: nowrap; }

  .recipe-img { margin: 0 rem(75) rem(30) 0; }

  .recipe-instructions { width: 65%; }

  .recipe-instructions__item { margin-bottom: 0; }

}
