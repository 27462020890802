// ==========================================================================
// Page d'une recette
// ==========================================================================

@include media-breakpoint-up(lg) {

    .recipe-show {

        .recipe-ingredients {
            position: relative;

            &:before {
                @include illu-positioning("#{$path-img}/ingredients/myrtilles.png", contain);
                top: -25%; left: -50%;
                width: rem(385); height: rem(280);

                transform: translate(-50%, -50%);
            }
        }

        .recipe-header {
            position: relative;

            &:after {
                @include illu-positioning("#{$path-img}/ingredients/noix-cajou-eclatees.png", contain);
                width: rem(790); height: rem(390);
                bottom: -50%; right: -50%;

                transform: translate(25%, 0);
            }

        }

        .steps {
            position: relative;
            &:after {
                @include illu-positioning("#{$path-img}/ingredients/noix-composition.png", contain);
                width: rem(405); height: rem(295);
                bottom: -50%; right: 0;

                transform: translate(25%, 0);
            }
        }

        .cross-content-slider {
            position: relative;

            &:before {
                @include illu-positioning("#{$path-img}/ingredients/amandes-decortiquees.png", contain);
                bottom: 25%; left: -10%;
                width: rem(350); height: rem(340);

                transform: rotate(2deg) translateX(-100%);
            }
        }
    }
}

@include media-breakpoint-up(xl) {

    .recipe-show {

        .recipe-header {
            position: relative;

            &:after {
                @include illu-positioning("#{$path-img}/ingredients/noix-cajou-eclatees.png", contain);
                width: rem(790); height: rem(390);
                bottom: -50%; right: -50%;

                transform: translate(15%, -50%);
            }

        }
    }
}

@media all and (min-width: 1200px) and (max-width: 1340px) {
    .recipe-show {

        .recipe-header {

            &:after { transform: translate(45%, 0); }

        }
    }
}

@include media-breakpoint-up(xxl) {

    .recipe-show {

        .recipe-header {
            &:after { transform: translate(15%, -50%); }
        }

        .cross-content-slider {

            &:before { transform: rotate(2deg) translateX(-50%); }
        }
    }
}
