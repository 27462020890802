// ==========================================================================
// Notation recette
// ==========================================================================

.rating-hearts {

  .hearts__list {
    list-style-type:none;
    padding:0;

    > .heart {
      display:inline-block;

      background-image: url("#{$path-img}/icon_heart-disabled.svg");
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center center;
      width: rem(32); height: rem(32);

      /* Hover state of the hearts */
      &.hover {
        background-image: url("#{$path-img}/icon_heart.svg");
      }

      /* Selected state of the hearts */
      &.selected {
        background-image: url("#{$path-img}/icon_heart.svg");
      }
    }
  }
}
