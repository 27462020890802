// ==========================================================================
// Home footer
// ==========================================================================

@include media-breakpoint-up(lg) {
  .home-footer {
    position: relative;
    height: rem(405);

    &__cheese{
      width: rem(485);
      height: rem(399);
      background-image: url("#{$path-img}/ingredients/cheese.png");
      background-size: contain;
      background-repeat: no-repeat;
      position:absolute;
      bottom: 0;
      left: 40%;
      z-index: 1;
    }
  }
}
