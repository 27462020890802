// ==========================================================================
// Testimony
// ==========================================================================

.custom-block-left-text {
	&__title {
		font-size: rem(22);
		line-height: rem(28);
		font-family: $barlow-semibold-italic;
		color: $light-black;
		text-decoration: underline;
		text-transform: none;
	}

	&__text {
		font-size: rem(22);
		line-height: rem(28);
		font-family: $barlow-semibold-italic;
		color: $light-black;
		margin-bottom: rem(20);
	}
}



@include media-breakpoint-up(md) {
	.custom-block-left-text {

		&__title {
			font-size: rem(26);
			line-height: rem(32);
		}

		&__text {
			font-size: rem(26);
			line-height: rem(32);
			margin-bottom: rem(80);
		}
	}
}