/**
 *
 * MODULES
 * Styles dédiés aux composants
 *
 * Regroupe les composants ré-utilisables du projet (pas de dépendance
 * contextuelle, pas de dépendance à leur contenu).
 *
 * Exemple : button, form, slider, accordion...
 *
 */

// ==========================================================================
// Accordions
// ==========================================================================

@import 'accordions';

// ==========================================================================
// Badge
// ==========================================================================

@import 'badge';

// ==========================================================================
// Banners
// ==========================================================================

@import 'banners';

// ==========================================================================
// Banners
// ==========================================================================

@import 'banner-event';


// ==========================================================================
// Blocks
// ==========================================================================

@import 'blocks';

// ==========================================================================
// Box-shadows
// ==========================================================================

@import 'box-shadows';

// ==========================================================================
// Breadcrumbs
// ==========================================================================

@import 'breadcrumbs';

// ==========================================================================
// Buttons
// ==========================================================================

@import 'buttons';

// ==========================================================================
// Buttons CTA
// ==========================================================================

@import 'buttons-cta';

// ==========================================================================
// Cards
// ==========================================================================

@import 'cards';

// ==========================================================================
// Circles
// ==========================================================================

@import 'circles';

// ==========================================================================
// Filters
// ==========================================================================

@import 'filters';

// ==========================================================================
// Forms
// ==========================================================================

@import 'forms';

// ==========================================================================
// Hooves
// ==========================================================================

@import 'hooves';

// ==========================================================================
// Images
// ==========================================================================

@import 'images';

// ==========================================================================
// Page header
// ==========================================================================

@import 'page-header';

// ==========================================================================
// Pagination
// ==========================================================================

@import 'pagination';

// ==========================================================================
// Products
// ==========================================================================

@import 'products';

// ==========================================================================
// Recipes
// ==========================================================================
@import 'recipes';

// ==========================================================================
// Search
// ==========================================================================

@import 'search';

// ==========================================================================
// Select2
// ==========================================================================

@import 'select2';

// ==========================================================================
// Sliders
// ==========================================================================

@import 'sliders';

// ==========================================================================
// Steps
// ==========================================================================

@import 'steps';

// ==========================================================================
// Sticky elements
// ==========================================================================

@import 'sticky';

// ==========================================================================
// Tables
// ==========================================================================

@import 'tables';

// ==========================================================================
// Tags
// ==========================================================================
@import 'tags';

// ==========================================================================
// Type
// ==========================================================================

@import 'type';


// ==========================================================================
// Video
// ==========================================================================

@import 'video';
@import 'video-youtube';

// ==========================================================================
// Cms
// ==========================================================================

@import 'cms';

// ==========================================================================
// Custom Block
// ==========================================================================

@import 'custom-blocks/index';

// ==========================================================================
// Recipe Block
// ==========================================================================

@import 'vignette';


// ==========================================================================
// Polaroid
// ==========================================================================

@import 'polaroid';

// ==========================================================================
// Polaroid
// ==========================================================================

@import 'card-history';

// ==========================================================================
// modal-redirect
// ==========================================================================

@import 'modal-redirect';
