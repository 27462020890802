// ==========================================================================
// Cards History
// ==========================================================================

.card-history {

  margin-bottom: rem(45);

  &__container {
    @include full-width(rem(260));
    position: relative;
  }

  &__image {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }

  &__date {
    font-size: rem(35);
    line-height: rem(35);
    font-family: $compotes-bold;
    color: $red-variant;
    margin: rem(20) 0 rem(10) 0;
  }

  &__title {
    display: block;
    font-size: rem(24);
    line-height: rem(28);
    font-family: $barlow-semibold;
    color: $black;
    text-transform: none;
  }

  &__description {
    font-size: rem(18);
    line-height: rem(24);
    color: $black;
    margin-bottom: 0;
  }


  /* Variante */
  &--video {
    .card-history {
      &__container {
        &:before {
          content: "";
          display:block;
          width: rem(60);
          height: rem(60);
          position: absolute;
          z-index: 1000;
          background-image: url("#{$path-img}/icon_player.svg");
          background-repeat: no-repeat;
          background-size: cover;
          background-position: center;
          top:50%;
          left:50%;
          transform: translate(-50%,-50%);
        }
      }
    }
  }

  &--mini {
    text-align: right;

    .card-history {
      &__container {
        margin-left: auto;
        transform: rotate(1deg);
        @include full-width(rem(221),rem(221));
      }
    }
  }

  &--mini-variant {
    text-align: right;

    .card-history {
      &__container {
        margin-left: auto;
        transform: rotate(1deg);
        @include full-width(rem(221),rem(221));
      }
    }
  }

  &--medium {
      .card-history {
        &__container {
          transform: rotate(-2deg);
        }
      }
  }

  &--large {
    .card-history {
      &__container {
        transform: rotate(-2deg);
      }
    }
  }

  &--big {
    text-align: right;

    .card-history {
      &__container {
        margin-left: auto;
        transform: rotate(2deg) translateX(5%);
        @include full-width(rem(285),rem(208));
      }
    }
  }
}

@include media-breakpoint-up(lg) {

  .card-history {
    margin-bottom: rem(75);
    flex-basis: 50%;
    display: flex;
    flex-direction: column;

    &:nth-child(2) {
      margin:0 rem(60) rem(45);
    }

    &--mini {
      align-items: flex-end;
    }

  }
}

@include media-breakpoint-up(xl) {

  .card-history {

    display: flex;
    flex-direction: column;
    flex: 0 0 rem(330);

    &:nth-child(2) {
      margin:0 rem(60) rem(70);

        &.card-history--big { margin: 0 0 rem(70) rem(50); }
        &:not(.card-history--big) { margin: 0 rem(160) rem(70) rem(50); }
    }

    &__date {
      font-size: rem(80);
      line-height: rem(80);
    }

    &__title {
      font-size: rem(28);
      line-height: rem(31);
    }


    &--medium {
      flex: 0 0 rem(330);
      padding-top: rem(40);
      position: relative;
      z-index: 1;
    }

    &--mini {
      flex: 0 0 rem(320);
      padding-top: rem(80);
      transform: translateX(-35%);
    }

    &--mini-variant {
      padding-top: rem(40);
      transform: translateX(-35%);
    }

    &--large {
      flex: 0 0 rem(320);

      .card-history {
        &__container {
          @include full-width(rem(316), rem(316));
        }
      }
    }

    &--big {
      flex: 0 0 rem(516);
      transform: translateX(-35%);
      align-items: flex-end;

      .card-history {

        &__title {
          max-width: rem(355);
        }

        &__description {
          max-width: rem(340);
          margin-left: auto;
        }

        &__container {
          @include full-width(rem(516),rem(343));
          position: relative;
          z-index: -1;
        }
      }
    }

    &--mini-variant {
      align-items: flex-end;
      flex: 0 0 rem(330);
    }
  }
}

// ==========================================================================
// Illustrations
// ==========================================================================

// Paths ingredients
$raisin: "#{$path-img}/ingredients/grains-raisin-blanc.png";
$persil: "#{$path-img}/ingredients/persil-home.png";
$noisettes: "#{$path-img}/ingredients/noisettes-entieres.png";
$noix: "#{$path-img}/ingredients/cerneaux-noix-2.png";
$pistaches: "#{$path-img}/ingredients/pistaches-entieres.png";
$mache: "#{$path-img}/ingredients/jeune-pousse-3.png";


@include media-breakpoint-up(xl) {

    .row-history {
        position: relative;

        &:nth-child(1) {

            &:before {
                top: 15%; left: -45%;
                width: rem(480); height: rem(400);

                @include illu-positioning($raisin, contain);
            }
        }


        &:nth-child(2) {

            &:before {
                top: 0; right: -14%;
                width: rem(180); height: rem(125);

                @include illu-positioning($persil, cover);
                background-position-y: top;
            }

            &:after {
                top: 15%; right: -18%;
                width: rem(200); height: rem(165);
                @include illu-positioning($noisettes, contain);
            }
        }

        &:nth-child(3) {

            &:before {
                bottom: -7%; left: -18%;
                width: rem(245); height: rem(285);

                @include illu-positioning($noix, contain);
                background-position-x: right;
            }
        }

        &:nth-child(4) {

            &:before {
                top: 40%; right: -35%;
                width: rem(385); height: rem(335);

                @include illu-positioning($pistaches, contain);
            }

            &:after {
                bottom: -10%; right: -18%;
                width: rem(160); height: rem(160);

                @include illu-positioning($mache, contain);
            }
        }

        &:nth-child(6) {

            &:before {
                left: -15%; top: 40%;
                width: rem(165); height: rem(97);

                @include illu-positioning($persil, cover);
                background-position-y: bottom;
            }
        }

    }
}
