// ==========================================================================
// Accordions
// ==========================================================================

.card-header {
    margin-bottom: 0;
    padding: 0;

    background: none;
    border-bottom: none;

    &:first-child { border-radius: unset; }

    h2 {
        position: relative;
        color: $black;
        font-family: $barlow-semibold;
        font-size: rem(20);
        text-transform: initial;
        cursor: pointer;

        &:after {
            content: '';
            position: absolute;
            top: 60%; right: 0;
            height: rem(22); width: rem(22);
            margin-left: unset;

            transform: translateY(-50%);
            background-image: url("#{$path-img}/arrow_black.svg");
            background-repeat: no-repeat;
            background-size: contain;
            background-position: center center;
            border: none;
            vertical-align: unset;
        }
    }
}

.card-body {
    margin-top: rem(15);
    padding: 0;
}

@include media-breakpoint-up(md) {

    .card-body { width: 75%; }
}
