// ==========================================================================
// Typographie
// ==========================================================================

h1, h2, h3, h4, h5, .h--like {
  font-family: $compotes-bold;
  color: $primary;
  line-height: 1;
  text-transform: uppercase;
}

h1 {
  font-size: rem(35);
  margin-bottom: rem(20);
}
.h1--hp { font-size: rem(50); }

h2, .h2--like {
  font-size: rem(35);
  margin-bottom: rem(25);

}
.h2--min { font-size: rem(24); }

h3, .h3--like { font-size: rem(28); }

h4, .h4--like { font-size: rem(24); }

.intro {
  font-family: $barlow-regular;
  font-size: rem(20);
  line-height: 1.3;
  color: $black;
}

@include media-breakpoint-up(md) {

  h1 {
    font-size: rem(80);
    line-height: 1;
  }
  .h1--hp { font-size: rem(100); }

  h2, .h2--like {
    margin-bottom: rem(35);
    font-size: rem(60);
  }
  .h2--min {
      margin-bottom: rem(30);
      font-size: rem(45);
  }

  h3, .h3--like { font-size: rem(45); }
  .h3--min { font-size: rem(35); }

  h4, .h4--like { font-size: rem(35); }
  .h4--min { font-size: rem(28); }
}

/*----------  Variantes de titres  ----------*/

// titres centrés
.title-center { max-width: rem(270); }

// titres colonne latérale
.title-aside { max-width: rem(170); margin-top: rem(40); }

@include media-breakpoint-up(md) {

  // titres centrés
  .title-center { max-width: rem(590); }

  // titres colonne latérale
  .title-aside { max-width: rem(200); }
}


// ==========================================================================
// Links
// ==========================================================================

a, a:visited {
  color: $black;
  font-family: $barlow-regular;

  &:hover, &:focus {
    color: $black;
    font-family: $barlow-semibold;
    text-decoration: none;
    outline: none;
  }
}
