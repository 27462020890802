// ==========================================================================
// Sticky elements
// ==========================================================================

// Menus sticky
.sticky.is-sticky {
  position: fixed;
  top: 0; left: 0; right: 0;
  width: 100%;

  z-index: 9999;
  background-color: $white;
}

/*----------  Variante menu homepage  ----------*/
.is-home, .is-header-transparent {

  .sticky.is-sticky {
    .nav-primary__link,
    .nav-primary__link:visited { color: $black; }
    .display-search svg path { fill: $black; }
  }
}

/*----------  Sticky bar bottom (product & recipe detail pages)  ----------*/
.sticky-bar {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: rem(12) rem(40);

  box-shadow: 0 rem(-2) rem(7) rem(-1) rgba(34,34,34,0.25);
  background: white;
  z-index: 9998;

  &.stick {
    position: fixed;
    bottom: 0; left: 0; right: 0;
  }
}

// ==========================================================================
// Media Queries
// ==========================================================================

@include media-breakpoint-up(md) {

  .sticky-bar { justify-content: space-between; }
}

@include media-breakpoint-up(lg) {

  .sticky-bar { padding: rem(12) rem(80); }
}
