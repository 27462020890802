// ==========================================================================
// Framework
// ==========================================================================

@import 'utilities/global'; // Override les variables Bootstrap

// Option 1: Inclure l'intégralité de Bootstrap
@import '~bootstrap/scss/bootstrap';

// Option 2: Inclure des parties de Bootstrap
// @link https://getbootstrap.com/docs/4.1/getting-started/theming/

// Requis
@import "~slick-carousel/slick/slick";
@import "~slick-carousel/slick/slick-theme";
@import "~lity/dist/lity.min.css";
@import "~select2/dist/css/select2.min.css";

// ==========================================================================
// Utilities
// ==========================================================================

@import 'utilities/index';

// ==========================================================================
// Elements
// ==========================================================================

@import 'elements/index';

// ==========================================================================
// Structure
// ==========================================================================

@import 'structure/index';

// ==========================================================================
// Modules
// ==========================================================================

@import 'modules/index';

// ==========================================================================
// Views
// ==========================================================================

@import 'views/index';

// ==========================================================================
// Vendors
// ==========================================================================

@import 'vendors/index.scss';

