// ==========================================================================
// Testimony
// ==========================================================================

.custom-block-slate {

	background-image: url("#{$path-img}/ardoise-mobile.jpg");
	background-size: cover;
	padding: rem(70) rem(30) rem(90) rem(30);
	transform: rotate(-1deg);
	margin-bottom: rem(40);

	&__reset {
		transform: rotate(1deg);
	}

	&__title {
		font-size: rem(38);
		line-height: rem(40);
		color: $light-grey;
		font-family: $coalhandluke;
		text-transform: none;
	}

	&__text {
		font-size: rem(22);
		line-height: rem(28);
		color: $light-grey;
		font-family: $barlow-semibold-italic;
	}
}



@include media-breakpoint-up(md) {
	.custom-block-slate {
	    padding: rem(70) rem(130) rem(90) rem(60);
		background-image: url("#{$path-img}/ardoise-desktop.jpg");
		transform: rotate(0deg);

		&__reset {
			transform: rotate(0deg);
		}

		&__text {
			font-size: rem(26);
			line-height: rem(32);
		}
	}
}
