// ==========================================================================
// Recipe bloc
// ==========================================================================

.vignette {
  width: rem(270);
  margin-bottom: rem(40);

  &__image {

  }

  &__title {
    font-family: $barlow-semibold;
    font-size: rem(24);
    line-height: rem(28);
    color:$black;
    text-transform: none;
  }

  &__presentation {
    display: flex;
    justify-content: space-between;
  }

  &__arrow {
    flex-shrink: 0;
    margin-top: rem(5);
  }

  &__image-container {
    margin-bottom: rem(20);
  }

  &--portrait {
    .vignette {

      &__title {
        margin-left: rem(20);
      }

      &__image-container {
        transform: rotate(-5deg);
      }

      &__image {
        border: rem(6) solid $white;
        box-shadow: rem(-5) rem(-5) rem(16) rem(-5) rgba(34,34,34,0.4);

        max-height: rem(175);
        width: 100%;
        object-fit: contain;
      }
    }
  }
}

@include media-breakpoint-up(md) {
}
