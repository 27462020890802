// ==========================================================================
// Box-shadows
// ==========================================================================

.box-shadow {
  position: relative;

  box-shadow: rem(-2) 0 rem(15) rem(6) rgba(0, 0, 0, 0.1);
  background-color: $white;

  &:after {
    content: '';
    position: absolute;
    top: 0; left: 0; bottom: 0; right: 0;

    z-index: -1;
    background-color: $white;
  }


  // ombres blocs inclinés
  &[class*=rotate] {
    box-shadow: none;

    &:before {
      position: absolute;
      content: '';
      top: rem(23); left: rem(5); bottom: 0;
      width: 5%;

      z-index: -1;
      background: rgba(0, 0, 0, 0.1);
      box-shadow: rem(-10) rem(-10) rem(10) rgba(0, 0, 0, 0.1);
      transform: rotate(-1.5deg);
    }

    &:after {
      content: '';
      position: absolute;
      top: 0; bottom: 0; left: 0; right: 0;

      z-index: -1;
      background-size: auto 100% !important;
      box-shadow: rem(-7) rem(-5) rem(15) rem(-7) rgba(34,34,34,0.25);
    }
  }

  /*----------  Variante bloc large  ----------*/

  &.block--wide {
    &:after { right: -100%; }

    // ombres blocs inclinés
    &[class*=rotate] {

      &:before {
        left: rem(-10);
      }

      &:after { left: rem(-20); }
    }
  }

}

// ==========================================================================
// Media Queries
// ==========================================================================

@include media-breakpoint-up(md) {

  .box-shadow {

    /*----------  Variante bloc large  ----------*/
    &.block--wide {

      // ombres blocs inclinés
      &[class*=rotate] {

        &:before {
          left: rem(10);
          width: 5%;
          transform: rotate(-1deg);
        }

        &:after { left: 0; }
      }
    }
  }

}

@include media-breakpoint-up(xxl) {
  .box-shadow {

    /*----------  Variante bloc large  ----------*/

    &.block--wide {
      &:after { right: 0; }
    }
  }
}