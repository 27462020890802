// ==========================================================================
// Page liste catégorie de produits
// ==========================================================================

@include media-breakpoint-up(lg) {

    .product-category-list {

        .cross-content-slider {
            position: relative;

            &:before, &:after {
                content: '';
                position: absolute;

                background-repeat: no-repeat;
                background-size: contain;
                z-index: 100;
            }

            &:before {
                bottom: 25%; left: 0;
                width: rem(210); height: rem(150);

                background-image: url("#{$path-img}/ingredients/jeune-pousse-3.png");
                transform: translateX(-50%);
            }

            &:after {
                top: -25%; right: -50%;
                width: rem(300); height: rem(340);
                background-image: url("#{$path-img}/ingredients/noisettes-decortiquees-2.png");
                transform: translate(-80%, -50%);
            }
        }
    }
}

@include media-breakpoint-up(xl) {
    .product-category-list {

        .cross-content-slider {

            &:after { transform: translate(-35%, -50%);  }
        }
    }
}

@include media-breakpoint-up(xxl) {

    .product-category-list {

        .cross-content-slider {

            &:before { transform: translateX(-35%); }

            &:after {
                right: -25%;
                transform: translate(-40%, -50%);
            }
        }
    }
}
