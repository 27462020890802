// ==========================================================================
// Our history
// ==========================================================================


@include media-breakpoint-up(xl) {
  .our-history {
    .page-header {
      position: relative;

      &:after {
        content: '';
        position: absolute;
        width: rem(550);
        height: rem(280);
        background-image: url("#{$path-img}/cheese.png");
        background-repeat: no-repeat;
        background-position: right bottom;
        background-size: contain;
        right: 0;
        top: 0;
      }
    }
  }

  .our-skills__container {
      position: relative;

      // patte de chèvre
      &:before {
          top: 25%; left: -38%;
          width: rem(465); height: rem(410);

          @include illu-positioning("#{$path-img}/ingredients/patte-gauche.png", contain);
      }

      // figues
      &:after {
          top: -15%; right: -50%;
          width: rem(465); height: rem(490);

          @include illu-positioning("#{$path-img}/ingredients/figues-ouvertes.png", contain);
      }
  }
}

@include media-breakpoint-up(xxl) {

    .our-skills__container {
        // patte de chèvre
        &:before { left: -30%; }
        // figues
        &:after { right: -30%; }
    }
}

@media all and (min-width: 1890px){
    .our-skills__container { &:before { left: -40%; } }
}

@media all and (min-width: 2100px){
    .our-skills__container { &:before { left: -50%; } }
}

@media all and (min-width: 2380px){
    .our-skills__container { &:before { left: -60%; } }
}
