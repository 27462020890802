// ==========================================================================
// Page de résultat de recherche
// ==========================================================================

@include media-breakpoint-up(lg) {

    .search-index {

        .product-results {
            position: relative;

            &:before {
                bottom: 25%; left: -25%;
                width: rem(135); height: rem(210);

                @include illu-positioning("#{$path-img}/ingredients/coriandre-2.png", contain);
            }

            &:after {
                top: -15%; right: -45%;
                width: rem(385); height: rem(280);

                @include illu-positioning("#{$path-img}/ingredients/myrtilles.png", contain);
                transform: rotate(-45deg);
            }
        }

        .recipe-results {
            position: relative;

            &:after {
                top: 0; right: -40%;
                width: rem(285); height: rem(480);

                @include illu-positioning("#{$path-img}/ingredients/noisettes-decortiquees-unified.png", contain);
                transform: rotate(-15deg);
            }
        }
    }
}


@include media-breakpoint-up(xxl) {

    .search-index {
        .product-results {
            &:before { left: -15%; }
            &:after { right: -30%; }
        }

        .recipe-results {
            &:after { right: -25%; }
        }
    }
}
