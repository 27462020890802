// ==========================================================================
// Custom key dates
// ==========================================================================

.custom-block-key-dates {
	&__date {
		font-size: rem(40);
		color: $light-black;
		font-family: $compotes-bold;
	}

	&__text {
		font-size: rem(20);
		line-height: rem(26);
		color: $light-black;
		font-family: $barlow-regular;
	}
}