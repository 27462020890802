/**
 *
 * Custom Blocks
 * Styles dédiés aux custom blocks
 *
 * Exemple : button, form, slider, accordion...
 *
 */

// ==========================================================================
// Custom Blocks Element
// ==========================================================================

@import 'actu';
@import 'header';
@import 'image';
@import 'image-text';
@import 'testimony';
@import 'key-dates';
@import 'slate';
@import 'left-text';
@import 'video';
@import 'back-text';
@import 'recipes';

