// ==========================================================================
// Footer
// ==========================================================================

.footer {
  position: absolute;
  width: 100%;
  bottom: 0;
  z-index: 9997;
  overflow: hidden;
  background-color: $white;
  box-shadow: 0 rem(2) rem(10) rem(1) rgba(0, 0, 0, 0.2);
}

.footer__container {
    position: relative;
    background-color: $white;
}

.footer__wrapper {
  display: flex;
  flex-direction: column;
  padding-bottom: rem(15);

  text-align: center;
}

.footer__block { margin-top: rem(20); margin-bottom: rem(20); }

.footer__logo {
  max-width: rem(130);
  margin-top: rem(30);
}

.footer__title {
  display: flex;
  flex-direction: column;
  margin-bottom: rem(20);

  color: $black;
  font-size: rem(30);
  line-height: 1;
  font-family: $compotes-bold;
  text-transform: uppercase;

    &--1 {}
    &--2 {
        margin-left: 5px;
    }
}

.footer__nav {
  margin-bottom: 0;
  order: 5; /* display in penultimate position */
}

.footer__menu {
  text-align: left;
  font-family: $barlow-regular;
  font-size: rem(18);
  line-height: normal;
}

.footer__link {
    margin-bottom: rem(15);
    white-space: nowrap;

    &::before {
        display: block;
        content: attr(data-text);
        font-family: $barlow-semibold;
        height: 0;
        overflow: hidden;
        visibility: hidden;
    }
 }

.footer__img {
  position: absolute;
  bottom: 0; right: 0;
  max-width: rem(179);

  order: 6; /* display in last position */
}

// ==========================================================================
// Media Queries
// ==========================================================================

@include media-breakpoint-up(md) {
    .footer {

        /*----------  Section au-dessus du footer  ----------*/
        &--top {
            position: relative;
            margin-bottom: rem(150);
            margin-top: 0;
            overflow: hidden;

            &:before {
                content: '';
                position: absolute;
                top: 50%; left: 7%;
                height: rem(170); width: rem(170);

                background-image: url("#{$path-img}/ingredients/basilic-2-home.png");
                background-repeat: no-repeat;
                background-size: contain;
                background-position: center center;
            }

            &:after {
                content: '';
                position: absolute;
                top: 25%; right: 0;

                height: rem(650); width: rem(800);
                background-image: url("#{$path-img}/ingredients/miel.png");
                background-repeat: no-repeat;
                background-size: contain;
                transform: translate(50%, -50%);
            }
        }

        /*----------  Footer par défaut  ----------*/
        &--cheese {
            z-index: unset;
            overflow: visible;
            margin-top: rem(85);

            &:before {
                content: '';
                position:absolute;
                top: rem(-180); left: 40%;
                width: rem(485); height: rem(400);

                z-index: -1;
                background-image: url("#{$path-img}/ingredients/cheese.png");
                background-size: contain;
                background-repeat: no-repeat;
            }
        }
    }
}

/* min-width 992px */
@include media-breakpoint-up(lg) {

  .footer__container {}

  .footer__wrapper {
    padding-top: rem(40); padding-bottom: rem(35);
    flex-direction: row;
    justify-content: flex-start;

    text-align: left;
  }

  .footer__block { margin-top: 0; margin-bottom: 0; margin-right: rem(50); }

  .footer__nav {
    display: flex;
    flex-direction: column;
    order: 0; /* display in first position */
    flex-basis: rem(370);
  }

  .footer__menu {
    margin-bottom: 0;

    ul { column-count: 2; }
  }

  .footer__block--contact { text-align: center; }

  .footer__logo { margin-top: rem(30); }

}

/* min-width 1200px */
@include media-breakpoint-up(xl) {
  .footer__container {
    max-width: 100%;
    margin-right: 0;
    padding-left: rem(80);
  }

  .footer__nav {
    flex-basis: rem(370);
  }

  .footer__logo { margin-top: rem(25); }

  .footer__img { max-width: rem(225); }

  .footer__block { margin-right: rem(100); }

  .footer__wrapper {
    margin-right: rem(100);
  }
}

// dirty hack pour forcer le passage du titre sur une ligne
@media all and (min-width: 1365px) {
  .footer__title { flex-direction: unset; }
}

/* min-width : 1441px */
@include media-breakpoint-up(xxl) {
  .footer__container {
    max-width: rem(1280);
    margin-right: auto;
    padding-left: rem(15);
  }
  .footer__wrapper { margin-right: 0; }
  .footer__img { transform: translateX(30%); }
}
