// ==========================================================================
// CMS
// ==========================================================================

/**
 * Tous les styles de ce module ciblent les contenus contribués par l'utilisateur (contenus et blocs).
 *
 * Ce qui implique qu'on ne peut pas cibler les éléments par des classes, mais via un
 * sélecteur d'éléments (.wysiwyg h2, .wysiwyg a...).
 *
 * Pour les contenus, il faut englober le contenu par la classe `.wysiwyg`.
 */

.wysiwyg {
  /* @note : Tous les styles appliqués aux contenus contribués doivent être placés ici */

  h2 {
    margin-bottom: rem(10);
    padding: 0;

    font-size: rem(30);
    line-height: 1.3;
    font-family: $compotes-bold;
    color: $red-variant;
  }

  h3 {
    margin-top: rem(25); margin-bottom: rem(10);
    font-size: rem(24);
    line-height: 1.3;
    color: $red-variant;
  }

  h4 {
    font-size: rem(20);
    line-height: 1.3;
    color: $light-black;
  }

  p {
    margin-bottom: rem(25);
    font-size: rem(18);
    line-height: 1.33;
    font-family: $barlow-regular;
    color: $light-black;
  }

  ul, ol {
    margin-bottom: rem(20);
  }

  ol {
    padding-left: rem(15);
    li {
      padding-left: rem(5);
      &:before {
        content: '';
      }
    }
  }

  li {
    position: relative;
    padding-left: rem(15);
    font-family: $barlow-regular;
    font-size: rem(18);
    line-height: 1.33;
    margin-bottom: rem(10);

    &:before {
      content:'•';
      color: $red-variant;
      position: absolute;
      top: 0;
      left: 0;
    }
  }

  a {
    color: $black;
    text-decoration: underline;
  }


  caption {
      caption-side: top;
  }
}

// ==========================================================================
// Texte riche
// ==========================================================================

.rich-text {
    a, a:visited {
        text-decoration: underline;

        &:hover, &:focus { text-decoration: underline; }
    }
}

// ==========================================================================
// Media Queries
// ==========================================================================

@include media-breakpoint-up(md) {
  .wysiwyg {
    /* @note : Tous les styles appliqués aux contenus contribués doivent être placés ici */

    h2 {
      font-size: rem(45);
      margin: rem(50) 0 rem(25) 0;
    }

    h3 {
      margin-top: rem(30);
      font-size: rem(35);
      color: $red-variant;
    }

    h4 {
      font-size: rem(28);
      font-family: $compotes-bold;
      color: $red-variant;
    }

    p {
      margin-bottom: rem(30);
      font-size: rem(20);
      line-height: 1.3;
    }

    ul, ol {
      margin-bottom: rem(40);
    }

    li {
      font-size: rem(20);
      line-height: 1.3;
      margin-bottom: rem(20);
    }
  }
}
