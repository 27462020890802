// ==========================================================================
// Page liste catégorie de recettes
// ==========================================================================

@include media-breakpoint-up(lg) {

    .recipe-category-list {

        .illu__container {
            position: relative;

            &:before, &:after {
                content: '';
                position: absolute;

                background-repeat: no-repeat;
                background-size: contain;
                z-index: 100;
            }

            &:before {
                bottom: 30%; left: 0;
                width: rem(350); height: rem(340);

                background-image: url("#{$path-img}/ingredients/amandes-decortiquees.png");
                transform: rotate(2deg) translateX(-100%);
            }

            &:after {
                top: 30%; right: -50%;
                width: rem(400); height: rem(340);
                background-image: url("#{$path-img}/ingredients/tomates.png");
                transform: translate(-30%, -50%);
            }
        }
    }
}

// Breakpoint spécifique pour un affichage propre de l'illustration
@media (min-width: 1200px) and (max-width: 1340px) {

    .recipe-category-list {

        .illu__container {
            &:after { right: -75%; }
        }
    }
}

@include media-breakpoint-up(xl) {
    .recipe-category-list {

        .illu__container {

            &:before { transform: rotate(2deg) translateX(-85%); }
            &:after { transform: translate(-85%, -50%); }
        }
    }
}
