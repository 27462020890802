// ==========================================================================
// Tags
// ==========================================================================

.tag__item {
    display: inline-block;
    margin-right: rem(25); margin-bottom: rem(15);

    &:last-child { margin-bottom: 0; }
}

.tag {
  margin-bottom: rem(10);

  color: $primary;
  font-family: $barlow-regular;
  font-size: rem(18);
  line-height: 1.44;
  font-weight: normal;
  text-transform: uppercase;

  &--black {
    background: $black;
    color: $white;
  }
}

// tag outline
.tag--outline {
  padding: 0 rem(20);

  font-size: rem(18);
  font-family: $barlow-regular;
  line-height: 1.72;
  border: rem(1) solid $black;
  border-radius: rem(25);

  a, a:visited {
      &:hover, &:focus { font-family: $barlow-regular; }
  }

  &.active {
    background: $black;

    a, a:visited { color: $white; }
  }
}

/*----------  Media Queries  ----------*/

@include media-breakpoint-up(md) {
    .tag__item{ margin-bottom: 0; }
}

@include media-breakpoint-up(lg) {

  .tags { margin-bottom: rem(45); }
}

// ==========================================================================
// 'Bien manger' (page edito)
// ==========================================================================

.well-eating__tags {
  margin-bottom: rem(20);

  .tags__list { display: flex; flex-direction: column; }

  .tag__item { margin-left: auto; margin-right: auto; }

  .tag--outline {
    &:hover, &:focus, &:active, &.active {
      background: $black;

      a, a:visited { color: $white; }
    }
  }

  .slick-slide {
    margin-left: rem(7);
    &:first-child { padding-left: rem(20); }
  }
}

/*----------  Media Queries  ----------*/
@include media-breakpoint-up(md) {

  .well-eating__tags {
    margin-bottom: rem(45);

    .tags__list { flex-direction: row; }
    .tag__item { margin-left: 0; margin-right: rem(25); }
  }

}
