// ==========================================================================
// Banners
// ==========================================================================

/*----------  Variables  ----------*/
$default-height-mobile: rem(230);
$default-height-tablet: rem(295);

$default-height-desktop: rem(265);
$large-height-desktop: rem(365);

/*----------  Global  ----------*/
.banner {
  position: relative;
  width: 100vw;
  height: $default-height-mobile;
  max-height: $default-height-mobile;

  border-radius: 0% 0% 100% 100% / 0% 0% 20% 20%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  background-color: $grey;

  @supports(clip-path: ellipse(110% 75% at 50% 25%)) {
    clip-path: ellipse(110% 75% at 50% 25%);
    border-radius: 0;
  }
}

// ==========================================================================
// Media Queries
// ==========================================================================


@include media-breakpoint-up(md) {

  .banner {
    height: $default-height-tablet;
    max-height: $default-height-tablet;
  }

}

@include media-breakpoint-up(xl) {

  .banner {
    height: $default-height-desktop;
    max-height: $default-height-desktop;

    border-radius: 0% 0% 50% 50% / 0% 0% 20% 20%;

    @supports(clip-path: ellipse(75% 75% at 50% 25%)) {
      clip-path: ellipse(75% 75% at 50% 25%);
      border-radius: 0;
    }
  }

  // Variante de taille
  .banner--large {
    height: $large-height-desktop;
    max-height: $large-height-desktop;
  }
}
