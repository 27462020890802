// ==========================================================================
// Custom block chapo
// ==========================================================================

.custom-block-header {
	font-family: $barlow-semibold;
	margin-bottom: rem(30);

	&__title {
		font-size: rem(30);
		line-height: rem(30);
        margin-bottom: rem(10);
	}

	&__text {
        font-family: $barlow-semibold;
		font-size: rem(20);
		line-height: rem(24);
	}
}



@include media-breakpoint-up(md) {
	.custom-block-header {

		&__title {
			font-size: rem(45);
			line-height: rem(45);
            margin-bottom: rem(15);
		}

		&__text {
			font-size: rem(22);
			line-height: rem(28);
		}
	}
}
