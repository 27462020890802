// ==========================================================================
// Custom block Image + Text
// ==========================================================================

.custom-block-recipes {

	margin-bottom: rem(40);

    &__recipe {
        display: flex;
        flex-direction: column;
        margin-bottom: rem(50);
    }

    &__block-title {
        margin-left: rem(60);
        margin-bottom: rem(20);
    }

	&__image-container {
		transform: rotate(-2deg);
		overflow: visible;
		align-self: center;
		margin-bottom: rem(40);
	}

	&__image {
		box-shadow: rem(-5) rem(-5) rem(16) rem(-5) rgba(34,34,34,0.4);
	}

	&__title {
		font-size: rem(26);
		line-height: rem(32);
		font-family: $barlow-semibold-italic;
		text-decoration: underline;
		text-transform: none;
		color: $light-black;
	}

	&__description {
		font-size: rem(26);
		line-height: rem(32);
		font-family: $barlow-semibold-italic;
		color: $light-black;
	}

	&__link {
		font-size: rem(18);
		line-height: rem(24);
		color: $light-black;
		text-decoration: underline;
		font-family: $barlow-regular;

		&:hover {
			color: $light-black;
		}
	}
}



@include media-breakpoint-up(md) {
	.custom-block-recipes {
        &__recipe {
            flex-direction: row;
        }

		&__image-container {
			align-self: flex-start;
			margin-bottom: 0;
		}


		&__resume {
			align-self: center;
			display: flex;
			flex-direction: column;
			margin-left: rem(40)
		}

		&__title {
			margin-top: rem(10);
		}
	}

}
