// ==========================================================================
// Banner Event
// ==========================================================================

.banner-event {

    position: relative;

    &__image {
        width: 100%;
        object-fit: cover;
    }

    &__presentation {
        display: flex;
        flex-direction: column;
        margin: rem(75) rem(20);
        position: absolute;
        top: 0;
    }

    &__presentation-title {
        color: white;
        font-family: $barlow-semibold;
        margin-bottom: rem(0);

        /* slabtext */
        p {
            font-size: rem(43);
            line-height: rem(44);
            font-family: $barlow-semibold;
            margin: 0;
        }
    }

    &__presentation-text {
        color: $white;
        font-size: rem(18);
        line-height: rem(26);
        font-family: $barlow-regular;
    }

    &__presentation-btn {
        align-self: flex-start;
    }

    @include media-breakpoint-up(md) {
        max-width: rem(1920);
        width: 100%;
        margin: 0 auto;

        &__presentation {
            left: 5%;
        }
    }
}
