// ==========================================================================
// Global formulaire
// ==========================================================================

// checkboxes
.form-check {
    position: relative;
    padding-left: 0;
    margin-bottom: rem(20);

    display: block;
    cursor: pointer;
    user-select: none;

    input[type="radio"], input[type="checkbox"] {
        top: 0;
        margin-left: rem(-30);
    }

    // cache l'input navigateur
    input[type="checkbox"] {
        position: absolute;
        height: 0; width: 0;

        cursor: pointer;
        opacity: 0;

        // si l'input est coché
        &:checked {
            ~ .checkmark {
                background-color: $primary;
                border: rem(1) solid $primary;

                &:after { display: block; }
            }
        }
    }

    // checkbox label
    &-label {
        position: relative;
        display: inline-block;
        margin-bottom: 0.625rem; margin-right: 1.875rem;
        padding-left: 1.875rem;

        transform: translateY(0);
        font-size: rem(18);
        cursor: pointer;
        user-select: none;

        &__link {
            color: $black;
            text-decoration: underline;
            &:hover {
                text-decoration: underline;
            }
        }
    }

    // checkmark custom
    .checkmark {
        &:after {
            left: rem(6); top: rem(2);
            width: rem(7); height: rem(12);

            border: solid white;
            border-width: 0 rem(2) rem(2) 0;
            transform: rotate(45deg);
        }
    }
}

// checkmark custom
.checkmark {
    position: absolute;
    top: rem(5); left: 0;
    height: rem(20); width: rem(20);

    z-index: -1;
    border: rem(1) solid $grey;

    &:after {
        content: "";
        position: absolute;
        display: none;
    }
}

// message d'erreur
.form__feedback {
    margin-top: rem(10);

    font-size: rem(14);
    color: $primary;
}

// bloc infos RGPD
.rgpd {
    font-size: rem(12);
    line-height: 1.5;
}


// ==========================================================================
// Formulaire contact
// ==========================================================================

.contact__form {
  position: relative;

  // version complète du formulaire
  &--full {
    max-width: none;
    padding: rem(20) rem(15) rem(50);
  }

  input {
    padding: rem(8) rem(25);

    background-color: $light-grey;
    border-radius: rem(25);
    border: none;
    font-size: rem(20);

    &[placeholder] { font-family: $barlow-semibold; }
  }

  [type=submit] {
    &.circle {
    position: absolute;
    top: 50%; right: rem(10);

    transform: translateY(-50%);
      width: rem(30); height: rem(30);

      background-image: url("#{$path-img}//arrow_white_next.svg");
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center center;
    }

  }
}

@include media-breakpoint-up(md) {
    .contact__form {

        // version complète du formulaire
        &--full {
            position: relative;
            max-width: none;
            margin-top: rem(20);
            padding: rem(50) rem(80) rem(65) rem(90);

            &:before, &:after {
                content: '';
                position: absolute;
                background-repeat: no-repeat;
                background-position: center center;
                background-color: transparent;
            }

            // ingredient ciboulette
            &:before {
                top: 40%; left: -40%;
                width: 100%; height: rem(220);

                background-size: cover;
                background-image: url("#{$path-img}/ingredients/ciboulette.png");
                transform: scaleX(-1) translate(50%, -50%);
            }

            // ingredient groseilles
            &:after {
                bottom: 0; right: 0;
                width: 100%; height: rem(340);

                background-image: url("#{$path-img}/ingredients/groseilles.png");
                background-size: contain;
                transform: translate(90%, 100%);
            }

            .form-control { width: 50%; }
        }

    }

}

@include media-breakpoint-up(xxl) {
    .contact__form {

        // version complète du formulaire
        &--full {
            // ingredient ciboulette
            &:before {
                left: -20%;
                transform: scaleX(-1) translate(100%, -50%);
            }
        }
    }
}

// ==========================================================================
// Bloc de confirmation d'envoi du contact
// ==========================================================================

@include media-breakpoint-up(md) {

    .contact__form--confirmation {
        position: relative;
        max-width: none;
        margin-top: rem(20); margin-bottom: rem(20);
        padding: rem(50) rem(165) rem(65) rem(100);

        &:before, &:after {
            content: '';
            position: absolute;
            background-repeat: no-repeat;
            background-position: center center;
            background-color: transparent;
        }

        // ingredient basilic
        &:before {
            width: 100%; height: rem(140);
            top: 50%; left: -25%;

            background-size: contain;
            background-image: url("#{$path-img}/ingredients/basilic-mini.png");
            transform: translate(-50%, -50%);
        }

    }
}

// ==========================================================================
// Formulaire de contact
// ==========================================================================

.custom-form {
    padding: rem(35) rem(15) rem(40);
    margin-left: rem(-20); margin-right: rem(-20);

    // labels
    .form-label {

        margin-bottom: 0;

        color: $dark-grey;
        transition: all ease .3s;
        transform: translateY(130%);

        &.focus {
            transform: translateY(20%);
            font-size: rem(14);
        }

        &.required {
            &:after {
                content: "*";
            }
        }
    }

    // inputs
    .form-control {
        display: block;
        width: 100%;
        padding-left: 0;

        transition: all ease .3s;
        border: none;
        border-bottom: rem(3) solid $grey;
        border-radius: rem(1);
        background: transparent;
        font-size: rem(20);

        &:focus {
            border-bottom: rem(3) solid $black;
            outline-offset: unset;
            box-shadow: none;
        }
    }

    // textarea
    .form__textarea {
        display: block;
        width: 100%;
        padding: rem(20) rem(10);

        transition: all ease .3s;
        border: solid rem(3) $grey;
        color: $black;
        resize: none;

        &:focus {
            border: rem(3) solid $black;
            outline-offset: unset;
            box-shadow: none;
        }

        &-label {
            color: $grey;
            transition: all ease .3s;
            transform: translate(10px, 190%);

            &.focus {
                font-size: rem(14);
                transform: translate(10px, 160%);
            }

            &.required {
                &:after {
                    content: '*';
                }
            }
        }
    }

    // texte d'aide
    .help-text {
        margin-top: rem(15); margin-bottom: rem(25);

        font-family: $barlow-light;
        font-size: rem(18);

        // texte provisoire (en attente évol) pour information réclamation
        &--complaint { margin-top: rem(20); margin-bottom: rem(0); }
    }

    // bouton submit
    #contact_submit {
        display: block;
        width: 100%;
    }

}


// ==========================================================================
// Formulaire de recherche
// ==========================================================================

.search-form {
    border-radius: rem(2);
    padding: rem(200);

    .form-group { width: rem(400); }
}


// ==========================================================================
// Media Queries
// ==========================================================================


@include media-breakpoint-up(md) {
    .custom-form { padding: rem(50) rem(230) rem(70) rem(65); }
}



@include media-breakpoint-up(lg) {
    .search-form {
        padding: rem(200) rem(300);

        .form-control {
            &:not(.form__textarea) { width: 100%; }
        }
    }
}


@include media-breakpoint-up(xl) {

    .custom-form {
        padding: rem(50) rem(230) rem(70) rem(80);
        margin-left: rem(-30); margin-right: 0;

        // override select2
        .select2-container { width: 60% !important; }

        .form-control {
            &:not(.form__textarea) { width: 60%; }
        }

        #contact_submit {
            display: inline-block;
            width: auto;
        }
    }

    .search-form {
        padding: rem(200) rem(400);

        .form-control {
            &:not(.form__textarea) { width: 100%; }
        }
    }
}


.select-shadow-height {
    overflow-y: auto;
    max-height: rem(200);
    box-shadow: 0 0 rem(15) 0 rgba(0,0,0,0.1)
}

.form-group--margin-top {
    margin-top: rem(40);
}
