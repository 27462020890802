// ==========================================================================
// Gestion de l'affichage du menu
// ==========================================================================

.display-nav {

  background: none;
  border: 0;
  cursor: pointer;

  &:focus {
    outline: 0;
  }

  .burger,
  .cross {
    width: rem(20);
    height: rem(20);
  }

}

@include media-breakpoint-up(md) {
  .display-nav { display: none; }
}

// ==========================================================================
// Nav primary
// ==========================================================================

.nav-primary {
  border-bottom: rem(1) solid $grey;
  border-top: rem(1) solid $grey;
  background-color: $white;
  display: none;

  &.is-show {
    display: block;
    position: fixed;
    left: 0; right: 0;
    max-height: calc(100% - 140px);
    z-index: 9999;
    overflow-y: auto;
  }
}

.nav-primary__list {
  list-style: none;
}

.nav-primary__item {
    box-sizing: content-box;
}

.nav-primary__link,
.nav-primary__link:visited {
  padding-top: rem(24); padding-bottom: rem(24);
  font-family: $barlow-semibold;

  &:hover, &:focus { padding-bottom: rem(23); }
}

// barre de recherche
.nav-primary__aside {
  padding: rem(15);
  z-index: 2;

  border-top: rem(1) solid $grey;
  background: $white;
}

// Eléments n'apparaissant pas en mobile
.nav-logo, .nav-info { display: none !important; }


/*----------  Niveau 1  ----------*/
.nav-primary__lvl-1 {
  position: relative;
  padding-left: 0;

  > .nav-primary__item {
    font-size: rem(24);

    &:not(:last-child) { padding: rem(24) rem(7); }

    &:not(:first-child):not(:last-child) { border-top: rem(1) solid $grey; }

    /*----------  Etat actif de la navigation  ----------*/
    &.is-show {
      padding: 0 rem(16);

      > .nav-primary__link {
        position: fixed;
        display: flex;
        font-family: $barlow-regular;
        width: 100%; height: rem(77);
        top: rem(74);
        padding-left: rem(39);

        background: white;
        z-index: 10;
        transform: translateX(-15px);

        &:before {
          content: '';
          position: absolute;
          left: rem(7);
          top: 50%;
          height: rem(22); width: rem(22);

          background-image: url("#{$path-img}/arrow_black.svg");
          background-repeat: no-repeat;
          background-size: contain;
          background-position: top left;
          transform: translateY(-50%) rotate(90deg);
        }

      }
      .nav-primary__subcontainer {
        display: block;
        padding-bottom: rem(80);
        z-index: 1000;
        transform: translateY(80px);
      }
      .nav-primary__lvl-2 { display: flex; }

    }

    > .nav-primary__link {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        padding: 0;
    }
  }
}

// ==========================================================================
// Menu de la home
// ==========================================================================

.is-home, .is-header-transparent {
  .nav-primary__link,
  .nav-primary__link:visited {
    color: $black;

    &:hover,
    &:focus { color: $black; }
  }

  .nav-primary {
    &:hover, &:focus {
      background: $white;
      border-bottom: rem(1) solid $grey;

      .nav-primary__link,
      .nav-primary__link:visited {
        color: $black;
      }
    }
  }

  .nav-primary__aside { background-color: $white; }
}

// ==========================================================================
// Nav secondary
// ==========================================================================

/*----------  Niveau 2  ----------*/
.nav-primary__subcontainer { display: none; }

.nav-primary__lvl-2 {
  display: none;


  // Styles dédies aux liens du sous-menu
  .nav-primary__link, .nav-primary__link:visited {
    font-family: $barlow-semibold;
    font-size: rem(22);
    line-height: rem(26);
  }

  .nav-primary__item {
    padding-top: rem(24); padding-bottom: rem(24);
    border-top: rem(1) solid $grey;
    line-height: 1.18;
  }
}

// ==========================================================================
// Media Queries
// ==========================================================================

@include media-breakpoint-up(md) {

  /*----------  Global  ----------*/
  .nav-primary { display: block; }

  .nav-primary__container {
    width: 100%;
    margin-right: auto;
    margin-left: auto;
  }

  .nav-logo, .nav-info { display: block !important; }

  // si menu de la home
  .is-home, .is-header-transparent {
    .nav-primary {
        background-color: transparent;
        border-bottom: none;
    }

    .nav-primary__link,
    .nav-primary__link:visited {
      color: $white;

      &:hover,
      &:focus { color: $white; }
    }

    // barre de recherche
    .nav-primary__aside { background-color: transparent; }
  }

  /*----------  Niveau 1  ----------*/
  .nav-primary__lvl-1 {
    display: flex;
    justify-content: center;
    margin: 0; padding: 0;

    > .nav-primary__item {
      padding: rem(16);
      text-align: center;
      line-height: 1;

      // on fixe la taille des col pour qu'au hover le bold ne fasse pas bouger les items du menu
      &:not(.nav-logo) { flex: 1 0 0; }

      // nav de recherche
      &.nav-primary__aside {
        padding-left: rem(7);
        padding-right: rem(7);
      }

      &:not(:first-child):not(:last-child) { border-top: none; }


      /*----------  Etat actif de la navigation  ----------*/
      &:hover {

        // affiche les items au même niveau (remove comportement mobile)
        ~ .nav-primary__item:not(.nav-primary__aside) { display: block; }

        > .nav-primary__link {
          position: initial;
          padding-left: 0;
          font-family: $barlow-semibold;

          &:before { content: none; }
        }

        .nav-primary__subcontainer {
          display: block;
          height: auto;

          z-index: 1000;
          opacity: 1;
          border-top: rem(1) solid $grey;
        }

        .nav-primary__lvl-2 { display: flex; }

      }

      &:last-child {
          display: flex;
          align-items: center;
          justify-content: center;
      }
    }
  }

  /*----------  Logo  ----------*/
  .nav-logo {
    display: flex !important;
    align-items: center;
    justify-content: center;
    flex: 0 0 7.8125rem;
    margin-left: rem(15); margin-right: rem(15);
    list-style: none;
  }

  /*----------  Barre de recherche  ----------*/
  .nav-primary__aside { border: none; }

  // ==========================================================================
  // Nav secondary
  // ==========================================================================

  /*----------  Niveau 2  ----------*/
  .nav-primary__subcontainer {
    position: absolute;
    display: flex;
    align-items: center;
    flex-direction: column;
    top: 100%; right: 0; left: 0;
    height: 0;

    opacity: 0;
    overflow: hidden;
    transition: .3s all;
    z-index: 1;
    background: $white;
    box-shadow: 0 rem(8) rem(10) 0 rgba(0, 0, 0, 0.1);
  }

  .nav-primary__lvl-2 {
    padding-top: rem(60); padding-bottom: rem(60);

    .nav-primary__item {
      padding-top: rem(12); padding-bottom: rem(12);

      border: none;
      text-align: left;
    }

    .nav-primary__link, .nav-primary__link:visited {
      font-family: $barlow-regular;
      padding-top: 0; padding-bottom: 0;

      &:hover,
      &:focus { font-weight: 900; }
    }
  }

  /*----------  Image sous-menu  ----------*/
  .nav__img {
    position: relative;
    @include full-size($navigation-img);

    transform: rotate(2deg);
    background-color: #d8d8d8;
    border: rem(10) solid $white;
    box-shadow: rem(-2) 0 10px 0 rgba(0,0,0,0.2);
  }

  /*----------  Bloc Le Saviez-Vous ?  ----------*/
  .nav-info {
    flex: 0 0 25.125rem;
    page-break-inside: avoid;
  }
  .nav-info__title {
      margin-bottom: rem(20);

      font-size: rem(24);
      font-family: $compotes-bold;
      color: $primary;
      line-height: 1;
      text-transform: uppercase;
  }

  .nav-info__text {
    margin-bottom: rem(30);

    font-family: $barlow-semibold;
    font-size: rem(20);
    line-height: 1.2;
  }

}

@include media-breakpoint-up(md) {

  .nav-primary__link, .nav-primary__link:visited { font-family: $barlow-regular; }

  .nav-info__title { font-size: rem(35); }
}

@include media-breakpoint-up(lg) {
  /*----------  Logo  ----------*/
  .nav-logo { margin-left: rem(30); margin-right: rem(30); }

}

@include media-breakpoint-up(xl) {

  /*----------  Niveau 1  ----------*/
  .nav-primary__lvl-1 {
      position: relative;

    > .nav-primary__item {
      padding: rem(24) rem(16);
      line-height: rem(29);

      // on fixe la taille des col pour qu'au hover le bold ne fasse pas bouger les items du menu
      &:not(.nav-logo) { flex: 0 0 10rem; }
      // menu 'complètement chèvre'
    }
  }

  /*----------  Niveau 2  ----------*/
  .nav-primary__subcontainer { top: 100%; }

}
