// ==========================================================================
// Mixins
// ==========================================================================

// Sizing shortcuts

@mixin sizing($width, $height: $width) { width: $width; height: $height; }

@mixin full-size($width, $height: $width) {
  @include sizing($width, $height);
  max-width: $width; max-height: $height;
}

@mixin full-width($width, $height: $width) {
  @include sizing($width, $height);
  max-width: $width; max-height: $height;
}

@mixin illu-positioning($img-url, $size) {
    content: '';
    position: absolute;

    background-image: url($img-url);
    background-repeat: no-repeat;
    background-size: $size;
    background-color: transparent;
    z-index: 100;
}
