// ==========================================================================
// Custom block actus
// ==========================================================================

.custom-block-actu {

    margin-bottom: rem(30);
    &__grid {
        display: grid;
        grid-template-columns: repeat(auto-fill, rem(300));
        justify-content: center;
        grid-gap: rem(40);
    }

    &__article {
        max-width: rem(300);
    }

    &__link {
        display: block;
        &:hover, &:focus {
            .custom-block-actu {
                &__container {
                    &:after {
                        background-image: linear-gradient(180deg, rgba(225, 31, 29, 0.3), rgba(225, 31, 29, 0.3));
                        background-position: 0 0;
                    }
                }

                &__item-title {
                    background-image: linear-gradient(180deg, $primary, $primary);
                    background-position: 0 0;
                    color: $white;
                }
            }
        }
    }

    &__container {
        max-width: rem(300);
        height: rem(300);
        margin-bottom: rem(30);
    }

    &__container {
        // red overlay
        position: relative;

        &:after {
            content: '';
            position: absolute;

            z-index: 1;
            background-size: 100% 100%;
            top: 0;
            background-repeat: no-repeat;
            background-position: 0 rem(300);
            transition: all .3s ease-out;
            display: block;
            width: 100%;
            height: 100%;
        }
    }

    &__image {
        object-fit: cover;
        width: 100%;
        height: 100%;
    }

    &__item-title {
        font-family: $barlow-semibold;
        font-size: rem(28);
        line-height: rem(31);
        color: $black;
        text-transform: none;
        background-position: 0 4rem;
        background-size: 100% 100%;
        background-repeat: no-repeat;
        transition: all .3s ease-out;
    }

    &__description {
        font-family: $barlow-regular;
        font-size: rem(20);
        line-height: rem(26);
        margin-bottom: 0;
    }

    @include media-breakpoint-up(md) {
        &__grid {
            grid-template-columns: 1fr 1fr;
        }
    }
}
