// ==========================================================================
// Page 404
// ==========================================================================

$footer-height-mobile: rem(208);
$footer-height-desktop: rem(387);

.page-not-found {
    padding-bottom: 0;

    &__footer {
        background: transparent;
        box-shadow: none;
        z-index: -1;

        &:after {
            content: '';
            position: absolute;
            width: rem(230);
            height: $footer-height-mobile;
            bottom: 0;
            right: 0;
            background-image: url("#{$path-img}/tete-chevre-large.png");
            background-repeat: no-repeat;
            background-position: right bottom;
            background-size: contain;
        }
    }

    &__wrapper {
        position: relative;
        height: $footer-height-mobile;

    }
}

@include media-breakpoint-up(lg) {

    .page-not-found {
        &__footer {
            &:after {
                width: rem(425);
                height: $footer-height-desktop;
            }
        }

        &__wrapper {
            height: $footer-height-desktop;

            &:before {
                bottom: -30%; left: 0;
                height: rem(335); width: rem(590);

                @include illu-positioning("#{$path-img}/ingredients/tomates.png", cover);
            }

            &:after {
                width: rem(485); height: rem(400);
                bottom: -50%; left: 40%;

                @include illu-positioning("#{$path-img}/ingredients/cheese.png", contain);
            }

        }
    }
}
