// ==========================================================================
// Pages de liste
// ==========================================================================

@include media-breakpoint-up(lg) {

    .list-page {

        .article-list {

            &:before, &:after {
                content: '';
                position: absolute;
                background-repeat: no-repeat;
                background-size: contain;
            }

            &:before {
                top: 25%; left: 0;
                width: rem(135); height: rem(210);

                background-image: url("#{$path-img}/ingredients/coriandre-2.png");
                transform: translateX(-50%);
            }

            &:after {
                top: rem(300); right: -5%;
                width: rem(430); height: rem(435);

                background-image: url("#{$path-img}/ingredients/citron.png");
                transform: translateX(50%);
            }

            .row {
                &:nth-child(3) {
                    position: relative;

                    &:after {
                        bottom: 0; right: 0;
                        width: rem(400); height: rem(455);
                        @include illu-positioning("#{$path-img}/ingredients/basilic.png", contain);

                        transform: translate(50%, 10%);
                    }
                }
                &:nth-child(4) {
                    position: relative;

                    &:before {
                        bottom: 0; left: -50%;
                        width: rem(580); height: rem(435);
                        @include illu-positioning("#{$path-img}/ingredients/groseilles.png", contain);

                        background-position: bottom;
                        transform: translateX(30%);
                    }
                }
                &:nth-child(5) {
                    position: relative;

                    &:after {
                        top: -25%; right: -10%;
                        width: rem(580); height: rem(435);
                        @include illu-positioning("#{$path-img}/ingredients/figues-ouvertes.png", contain);

                        transform: translateX(50%);
                    }
                }
            }
        }
    }
}
