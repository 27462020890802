// ==========================================================================
// Home
// ==========================================================================

.home {
  overflow-x: hidden;
}

.parallax {
  will-change: transform;
}

@include media-breakpoint-up(lg) {
  .home {
    padding-bottom: 0;
  }
}