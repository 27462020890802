// ==========================================================================
// Page de la FAQ
// ==========================================================================

@include media-breakpoint-up(xl) {
    .faq-index {

        .faq__content {
            position: relative;

            &:before, &:after {
                content: '';
                position: absolute;

                background-repeat: no-repeat;
                background-size: contain;
                z-index: 100;
            }

            &:before {
                top: 0; left: 0;
                height: rem(415); width: rem(480);

                background-image: url("#{$path-img}/ingredients/tomates.png");
                background-position-y: bottom;
                transform: translate(-80%, 80%);
            }

            &:after {
                top: 0; right: -30%;
                height: rem(400); width: rem(400);

                background-image: url("#{$path-img}/ingredients/myrtilles.png");
            }
        }
    }
}
@media all and (min-width: 1340px) {
    .faq-index {

        .faq__content {

            &:before { transform: translate(-50%, 80%); }
            &:after { top: 0; right: -20%; }
        }
    }
}
