// ==========================================================================
// Page header
// ==========================================================================

.page-header {
  flex: 0 0 60%;
  flex-grow: 1;
}

.page-header__title {
    margin-bottom: rem(20);
    line-height: 1.3;
}

// ==========================================================================
// Media Queries
// ==========================================================================

@include media-breakpoint-up(md) {

  .page-header {
    flex: 0 0 60%;
    flex-grow: 1;
    width: 90%;
  }

  .page-header__title { margin-top: rem(10); margin-left: 0; margin-right: 0; }

  .page-header__description { width: 50%; }

}

@include media-breakpoint-up(xl) {

    .page-header { max-width: map-get($grid-breakpoints, xxl); }
}
