// ==========================================================================
// Page détail Bien Manger
// ==========================================================================

@include media-breakpoint-up(lg) {

    .well-eating-show {

        .block--edito {

            &:before {
                content: '';
                position: absolute;
                top: rem(350); left: -35%;
                height: rem(4000);
                width: rem(1440);
                background-image: url("#{$path-img}/ingredients/ingredients-tpl-well-eating.png");
                background-repeat: repeat-y;
                background-size: contain;
                z-index: -1;
            }
        }
    }
}
