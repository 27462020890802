// ==========================================================================
// Select2
// ==========================================================================

.select2-container { width: 100% !important; }

.select2-container--default {

  .select2-selection--single {
    background-color: transparent;
    border: none;
    border-radius: unset;
    font-family: $barlow-regular;
    outline: none;

    .select2-selection__arrow {
      top: 50%;
      height: rem(22); width: rem(22);
      background-image: url("#{$path-img}/arrow_black.svg");
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center center;
      transform: translateY(-50%);

      b { display: none; }
    }

    .select2-selection__rendered {
        padding: 0;
        outline: none;
    }

  }

  .select2-results__option--highlighted[aria-selected] { background-color: $primary; }
  .select2-results__option[aria-selected=true] {
    padding: 0 rem(30);
    background-color: $light-red;
    color: $black;
  }

  .select2-results {
    >.select2-results__options {
      overflow-y: visible;
      max-height: none;
      padding-top: rem(10); padding-bottom: rem(20);
    }
  }
}

.select2-results__option { padding: 0 rem(30); }

.select2-dropdown {
  background: $white;
  border: none;
  border-radius: 0;
  text-align: right;
  font-family: $barlow-regular;
  font-size: rem(20);
  line-height: 1.3;
}
.select2-search--dropdown { display: none; }


.select-border-bottom {
    .select2-container{
        padding-bottom: rem(10);
        border-bottom: rem(3) solid $grey;
    }
}
