// ==========================================================================
// Content
// ==========================================================================

html { height: 100%; }

body {
  position: relative;
  min-height: 100%;
  padding-bottom: rem(588);

  background-image: url("#{$path-img}/fond_texture.png");
  background-repeat: repeat;
  background-size: contain;
  background-position: center center;
}

.content {
  margin: 0;

  overflow: hidden;
  font-family: $barlow-regular;
  font-size: rem(18);
  line-height: 1.33;
}

section { margin-top: rem(35); margin-bottom: rem(35); }

// font par défaut
p {
  font-family: $barlow-regular;
  font-size: rem(18);
  line-height: 1.33;
}

ul {
  margin-bottom: 0;
  padding-left: 0;

  list-style: none;
}

figure { margin: 0; }

img {
  max-width: 100%;
  height: auto;
}

// ==========================================================================
// Media Queries
// ==========================================================================

@include media-breakpoint-up(lg) {

  body { padding-bottom: rem(240); }

  .content {
    overflow: hidden;
    font-family: $barlow-regular;
    font-size: rem(20);
    line-height: 1.3;
  }

  section { margin-top: rem(70); margin-bottom: rem(70); }

  // font par défaut
  p {
    font-size: rem(20);
    line-height: 1.3;
  }

  // container spécifique page 'Notre Histoire'
  .container--our-history { max-width: rem(1140); }

}



// breakpoint spécifique pour container large
@media (min-width: 1340px) {

  // container large pour écrans xxl
  .container--xl { max-width: rem(1280); }
}
