// ==========================================================================
// Sliders
// ==========================================================================

/*----------  Variables  ----------*/
$aside-slider-max-width: rem(280);

/*----------  Slider produits - Homepage  ----------*/

.home-product-slick {
    .slick-list { overflow: visible; }

    .slick-slide {
        max-width: rem(250);
        margin-right: rem(40);
        text-align: center;
    }
}

/*----------  Slider produits - fiche recette  ----------*/

// slider produits soignon dans la recette
.recipe-products-slider {
  margin-left: rem(-15);

  .card {
    margin: auto;
    max-width: $aside-slider-max-width;
  }

  .card__img {
    display: block;
    height: auto; width: auto;
    margin: 0 0 rem(10) 0;
  }

  .slick-list { overflow: visible; }
  .slick-slide {
    max-width: rem(270);
    margin-right: rem(20); margin-left: rem(5);
  }
  .slick-track { left: rem(15);}
}

// slider étapes de la recette
.steps-slider {

  .slick-list { overflow: visible; }

  .slick-slide {
    max-width: rem(250) !important;
    margin-right: rem(40);
    text-align: center;
  }
  .box-shadow {
    &.block--wide:after { right: 0; }
    &[class*=rotate]:after {
      height: rem(265);
    }
  }

  // s'il n'y a qu'une seule étape de recette
  .box-shadow--h-auto {
    &[class*=rotate] {
      &:after { height: auto; }
    }
  }
}

/*----------  Slider produits - page résultats de recherche  ----------*/
.search-slider {
    .slick-slide {
        max-width: rem(270);
        margin-left: rem(5); margin-right: 0;
    }
}

// ==========================================================================
// Media Queries
// ==========================================================================

@include media-breakpoint-up(sm) {

    /*----------  Slider produits - fiche recette  ----------*/
    .recipe-products-slider {
        margin-left: rem(-45);

        .slick-list {
          overflow: hidden;
          transform: translateX(5%);
        }
    }
}
@include media-breakpoint-up(md) {

  /*----------  Points de navigation  ----------*/
  .slick-dots {
    position: initial;
    width: $aside-slider-max-width; max-width: $aside-slider-max-width;
    text-align: left;
    margin: rem(5) rem(30) 0 rem(30);

    li  {
      &:first-child { margin-left: 0; }

      &.slick-active button {
        &:before {
          font-size: rem(14);
          color: $primary;
        }
      }

      button {
        &:before {
          opacity: 1;
          font-size: rem(12);
          color: $grey;
        }
      }
    }
  }

  /*----------  Flèches de navigation  ----------*/
  .slick-prev, .slick-next {
    width: rem(30);
    height: rem(30);
    padding: 0;

    z-index: 1000;
    border-radius: rem(25);
    color: $white;
    white-space: nowrap;
    background-color: $black;

    &:hover, &:focus {
      color: $white;
      box-shadow: none;
      background-image: radial-gradient(circle at 49% 50%, #2c2c2c, $black);
    }

    &.disabled, &:disabled {
      opacity: 0.15;
    }

    &:before {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      width: rem(20);
      height: rem(20);

      opacity: 1;
      transform: translate(-50%, -50%);
      background-image: url("#{$path-img}/arrow_white_next.svg");
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center center;
    }
  }

  .slick-prev {
    left: rem(-20);

    &:before {
      transform: translate(-50%, -50%) rotate(180deg);
    }
  }
  .slick-next { right: rem(-45); }


  /*----------  Slider produits - fiche recette  ----------*/
  .recipe-products-slider {
    margin-left: rem(-30);

    .card__img { margin: 0 0 rem(25) 0; }

    .slick-slide { max-width: none; margin-left: rem(3); }
    .slick-list { transform: translateX(5%); }
  }

  /*----------  Slider produits - Homepage  ----------*/
  .home-product-slick {

    .slick-list {
      max-width: rem(500);
      max-height: rem(500);
      margin: 0 auto;
    }

    .slick-slide {
      max-width: none;
      margin-right: 0;
      max-height: rem(500);
      text-align: center;
    }

    .slick-prev, .slick-next {
      background-color: $red;
      width: rem(60);
      height: rem(60);
      border-radius: 50%;

      &:before {
        background-size: rem(45);
      }
    }
  }
}

@include media-breakpoint-up(md) {
  .home-product-slick {
    .slick-list {
      max-width: rem(600);
      max-height: rem(500);
    }
  }
}

@include media-breakpoint-up(xl) {
  .home-product-slick {
    .slick-list {
      max-width: rem(750);
      max-height: rem(500);
    }
  }
}


/*----------  Slider custom blocks ----------*/
.custom-block-slider {
  .slick-prev {
    left: rem(20);
  }
  .slick-next { right: rem(20); }

  .slick-dots {
    margin: 0 auto;
    text-align: center;
  }
}
