// ==========================================================================
// Custom block back text
// ==========================================================================

.custom-block-back-text {
	font-size: rem(15);
	line-height: rem(20);
	font-family: $barlow-italic;
	border-top: rem(1) solid rgba(30, 30, 30, 0.15);
	padding-top: rem(20);

	p {
		font-size: rem(15);
		line-height: rem(20);
		font-family: $barlow-italic;
		margin: 0;
	}

	ol, ul {
		padding-left: rem(10);
	}
}



@include media-breakpoint-up(md) {
	.custom-block-back-text {
		padding-top: rem(40);
	}
}
