// ==========================================================================
// Paths
// ==========================================================================

$path-img: '/build/img';
$path-fonts: '/build/fonts';

// ==========================================================================
// Custom
// ==========================================================================

$font-size-base-px: 16; // = 1rem
$form-group-margin-bottom: 0.75rem;

// ==========================================================================
// Grid
// ==========================================================================

$grid-gutter-width: 35px;

$grid-breakpoints: ();

$grid-breakpoints: map-merge(
  (
    xs: 0,
    sm: 576px,
    md: 768px,
    lg: 992px,
    xl: 1200px,
    xxl: 1441px
  ),
  $grid-breakpoints
);

$container-max-widths: ();

$container-max-widths: map-merge(
  (
    sm: 540px,
    md: 720px,
    lg: 960px,
    xxl: 1280px
  ),
  $container-max-widths
);

// ==========================================================================
// Tailles
// ==========================================================================

/*----------  Cards  ----------*/
// default
$card-default-xs: rem(250);
$card-default-mobile: rem(270);
$card-default-desktop: rem(295);
$card-default-desktop-large: rem(400);
$card-default-desktop-wide: rem(680);
$card-default-desktop-xl-wide: rem(730);

// recipes
$card-recipe-xs: rem(225);
$card-recipe-mobile: rem(260);
$card-recipe-desktop: rem(275);
$card-recipe-highlight: rem(420);
$card-recipe-highlight-xl: rem(325);
$card-recipe-large: rem(530);
$card-recipe-wide: rem(530);

// all recipes
$card-all-recipes: rem(285);
// well-eating
$card-well-eating: rem(185);

// cross articles
$cross-article-img: rem(265);

// navigation
$navigation-img: rem(205);

/*----------  Blocks  ----------*/
$block-tiny-img: rem(175);

$z-index-header: 1;

$edito-padding-xs: rem(15);
$edito-padding-md: rem(110);
$edito-tiny-img: rem(238);


// ==========================================================================
// Fonts
// ==========================================================================

$barlow-extralight: "BarlowCondensed ExtraLight", Arial, sans-serif;
$barlow-light: "BarlowCondensed Light", Arial, sans-serif;
$barlow-light-italic: "BarlowCondensed Light Italic", Arial, sans-serif;
$barlow-regular: "BarlowCondensed Regular", Arial, sans-serif;
$barlow-italic: "BarlowCondensed Italic", Arial, sans-serif;
$barlow-medium: "BarlowCondensed Medium", Arial, sans-serif;
$barlow-semibold: "BarlowCondensed Bold", Arial, sans-serif;
$barlow-semibold-italic: "BarlowCondensed SemiBold Italic", Arial, sans-serif;
$compotes-regular: "CompotesCitro Regular", Arial, sans-serif;
$compotes-bold: "CompotesCitro Bold", Arial, sans-serif;
$coalhandluke: "Coalhandluke", cursive;


// Font name: BarlowCondensed
// @link https://fonts.google.com/specimen/Barlow+Condensed
@font-face {
  font-family: 'BarlowCondensed ExtraLight';
  src: url('#{$path-fonts}/barlowcondensed-extralight-webfont.woff2') format('woff2'),
  url('#{$path-fonts}/barlowcondensed-extralight-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'BarlowCondensed Light';
  src: url('#{$path-fonts}/barlowcondensed-light-webfont.woff2') format('woff2'),
  url('#{$path-fonts}/barlowcondensed-light-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'BarlowCondensed Light Italic';
  src: url('#{$path-fonts}/barlowcondensed-lightitalic-webfont.woff2') format('woff2'),
  url('#{$path-fonts}/barlowcondensed-lightitalic-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'BarlowCondensed Regular';
  src: url('#{$path-fonts}/barlowcondensed-regular-webfont.woff2') format('woff2'),
  url('#{$path-fonts}/barlowcondensed-regular-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'BarlowCondensed Italic';
  src: url('#{$path-fonts}/barlowcondensed-italic-webfont.woff2') format('woff2'),
  url('#{$path-fonts}/barlowcondensed-italic-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'BarlowCondensed Medium';
  src: url('#{$path-fonts}/barlowcondensed-medium-webfont.woff2') format('woff2'),
  url('#{$path-fonts}/barlowcondensed-medium-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'BarlowCondensed Bold';
  src: url('#{$path-fonts}/barlowcondensed-semibold-webfont.woff2') format('woff2'),
  url('#{$path-fonts}/barlowcondensed-semibold-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'BarlowCondensed SemiBold Italic';
  src: url('#{$path-fonts}/barlowcondensed-semibolditalic-webfont.woff2') format('woff2'),
  url('#{$path-fonts}/barlowcondensed-semibolditalic-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

// Font name : Compotes Citro
@font-face {
  font-family: 'CompotesCitro Regular';
  src: url('#{$path-fonts}/compotes_citro-webfont.woff2') format('woff2'),
  url('#{$path-fonts}/compotes_citro-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'CompotesCitro Bold';
  src: url('#{$path-fonts}/compotes_citro_bold-webfont.woff2') format('woff2'),
  url('#{$path-fonts}/compotes_citro_bold-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

// Font name : CoalhandLuke_CG
@font-face {
  font-family: 'Coalhandluke';
  src: url('#{$path-fonts}/CoalhandLuke_CG.woff2') format('woff2'),
  url('#{$path-fonts}/CoalhandLuke_CG.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
