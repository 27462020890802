// ==========================================================================
// Blocks
// ==========================================================================

.block {
    position: relative;
}

.block__title {
    margin-bottom: rem(15);
}

.block__intro {
    margin-bottom: rem(15);
}

// blocs infos en plus
.block__content {
    display: flex;
}

.block__img {
    position: relative;
}

.content--illu {
    flex-direction: column;
    align-items: flex-start;
}

.block__content-wrapper {
    margin-top: rem(25);
}

.block__link {
    text-decoration: underline;
    color: $black;

    &:hover, &:focus {
        text-decoration: underline;
        color: $primary;
    }
}

/*----------  Variantes de taille  ----------*/

.block--wide {
    padding: rem(30) rem(15);
}

// filtres produits
.filters {

    .block__content {
        flex-direction: column;
        align-items: center;
    }

    .block__content-wrapper {
        margin-top: 0;
    }

    &.block--wide {
        padding-left: 0;
        padding-right: 0;
        width: 100%;
    }

    /*----------  Filtres de la FAQ  ----------*/
    &.filters--faq {
        &.block--wide {
            padding: rem(25) rem(20);
        }

        .block__content {
            justify-content: unset;
        }
    }
}

/*----------  Media Queries  ----------*/

@include media-breakpoint-up(md) {

    .block__content {
        display: flex;
        align-items: center;
    }

    /*----------  Variantes de taille  ----------*/
    .block--wide {
        margin-left: auto;
        margin-right: 0;
        padding: rem(80) 0 rem(20);
    }

    // filtres produits
    .filters {
        .block__content {
            flex-direction: row;
            justify-content: center;
        }

        .block__title {
            margin-right: rem(60);
            margin-bottom: 0;
        }

        &.block--wide {
            padding: rem(50) rem(15);
            width: 95%;
        }

        /*----------  Filtres de la FAQ  ----------*/
        &.filters--faq {
            &.block--wide {
                padding: rem(30) rem(35) rem(30) rem(45);
            }
        }
    }
}

@include media-breakpoint-up(xxl) {

    /*----------  Variantes de taille  ----------*/
    .block--wide {
        max-width: map-get($grid-breakpoints, xxl);
        margin-right: auto;
    }

}

// ==========================================================================
// Bloc 'Suggestion de dégustation'
// ==========================================================================

.tasting-suggestion {
    margin-left: auto;
    margin-right: auto;

    .block__content {
        flex-direction: column-reverse;
        align-items: flex-end;

        text-align: right;
    }

    .block__title {
        margin-left: auto;
    }

    .block__img {
        width: rem(320);
        height: rem(250);

        img {
            width: rem(320);
            height: rem(250);
        }
    }
}

@include media-breakpoint-up(md) {

    .tasting-suggestion {

        .block__img {
            width: rem(570);
            height: rem(380);

            img {
                width: rem(570);
                height: rem(380);
            }
        }
    }

}

@include media-breakpoint-up(xl) {

    .tasting-suggestion {

        .block__content {
            flex-direction: row-reverse;
            align-items: center;
        }

        .block__img {
            flex: 0 0 rem(580);
        }

        .block__content-wrapper {
            margin-right: rem(35);
            max-width: rem(325);
        }

        .block__title {
            max-width: rem(200);
        }
    }
}

// ==========================================================================
// Bloc contact
// ==========================================================================

.contact {
    max-width: rem(655);
    margin-top: rem(60);
    padding: rem(45) rem(25);

    z-index: 2;

    .block__title {
        position: absolute;
        top: rem(-10);

        z-index: -1;
        transform: translateY(-100%) rotate(1deg);
    }
}

/*----------  Media Queries  ----------*/

@include media-breakpoint-up(sm) {
    .contact {

        .block__title {
            top: rem(-15);
            transform: translateY(-100%) rotate(1deg);
        }
    }
}

@include media-breakpoint-up(md) {

    .contact {
        margin-top: rem(130);
        padding: rem(50) rem(140) rem(40) rem(110);

        .block__title {
            top: -55%;
            transform: translateY(0) rotate(1deg);
        }

        .block__content {
            font-size: rem(24);
            line-height: 1.17;
        }
    }
}

// ==========================================================================
// Bloc 'Bien Manger'
// ==========================================================================

.edito {

    .block__content {
        flex-flow: wrap;
        justify-content: center;
        align-items: flex-start;
    }

    .block__title {
        margin-bottom: rem(15);
    }

    .card {
        margin-bottom: 0;
    }
}

/*----------  Media Queries  ----------*/

@include media-breakpoint-up(sm) {

    .edito {
        padding: rem(25) rem(25) rem(25) rem(80);

        .block__title {
            margin-bottom: rem(15);
        }

        .block__content {
            justify-content: space-between;
        }

        .card {
            margin-bottom: rem(60);
        }
    }
}

@include media-breakpoint-up(md) {

    .edito {

        .block__content {
            justify-content: space-between;
        }

        .block__title {
            margin-bottom: rem(30);
        }

        .card {
            margin: auto 0;
        }

        // 2 articles
        &--2 {
            .block__content {
                max-width: rem(835);
            }
        }

        // 3 articles
        &--3 {
            .block__content {
                max-width: rem(950);
            }
        }
    }
}

@include media-breakpoint-up(xl) {

    .edito {
        position: relative;
        padding: 0 0 rem(25) rem(140);

        overflow: hidden;

        &:after {
            content: '';
            position: absolute;
            top: 50%;
            right: -20%;
            width: rem(560);
            height: rem(330);

            transform: rotate(2deg) translateY(-50%);
            background-image: url("#{$path-img}/ingredients/grains-raisin-blanc.png");
            background-repeat: no-repeat;
            background-size: contain;
        }

        .block__content {
            justify-content: space-between;
        }

        // 3 articles
        &--3 {
            .block__title {
                max-width: 60%;
            }
        }
    }

}

@include media-breakpoint-up(xxl) {

    .edito {
        &:after {
            right: -10%;
        }
    }
}

// ==========================================================================
// Page blog - liste articles
// ==========================================================================

.article-list {
    padding-top: rem(50);

    // 3 articles latéraux
    .article--highlight, .article--aside {
        .card__img {
            @include full-size($card-default-mobile);

            img {
                @include full-size($card-default-mobile);
            }
        }
    }

    .article--highlight {
        .card__img {
            margin-bottom: rem(25);
        }
    }
}

@include media-breakpoint-up(md) {

    .article-list {
        padding-top: rem(70);

        .article--highlight, .article--aside {
            .card__img {
                @include full-size($card-default-desktop);

                img {
                    @include full-size($card-default-desktop);
                }
            }
        }
    }
}

@include media-breakpoint-up(xl) {

    .article-list {

        .block__content {
            align-items: flex-start;
            justify-content: space-evenly;
        }

        // 3 articles latéraux
        .article--aside {
            .card.article {
                max-width: none;
                margin: 0 0 rem(30) 0;
            }

            .card__link {
                flex-direction: row;
                display: flex;
                max-width: 520px;
                align-items: flex-start;
            }

            .card__img {
                @include full-size($card-well-eating);
                margin: 0;

                // overlay rouge
                &:after {
                    @include full-size($card-well-eating);
                    background-position: 0 $card-well-eating;
                }

                img {
                    @include full-size($card-well-eating);
                }
            }

            .card__content {
                height: $card-well-eating;
                margin-left: 40px;
                flex: 0 0 295px;
            }

            .card__title {
                margin-bottom: 10px;
            }

            $font-size: rem(20);
            $line-height: 1.3;
            $line-to-show: 2;

            .card__excerpt {
                margin-bottom: 0;
                height: $font-size*$line-height*$line-to-show;
                overflow: hidden;
            }
        }

        // 3 articles latéraux
        .article--highlight {
            .card__img {
                width: 100%;
                height: $card-default-desktop-large;
                max-height: $card-default-desktop-large;
                max-width: none;

                //margin: 0;

                img {
                    object-fit: cover;
                    width: 100%;
                    height: 100%;
                    max-height: none;
                    max-width: none;
                }
            }
        }
    }

}

@include media-breakpoint-up(xxl) {
}

// ==========================================================================
// Bloc 'Bien manger' page blog
// ==========================================================================

.well-eating {
    &__title {
        font-size: rem(50);
        font-family: $compotes-bold;
    }

    &__subtitle {
        font-size: rem(20);
        line-height: rem(26);
    }
}

@include media-breakpoint-up(md) {

    .well-eating {
        &__title {
            font-size: rem(100);
        }
    }

    .well-eating--blog {

        .well-eating {

            &__raisin {
                position: absolute;
                width: rem(400);
                height: rem(374);
                right: rem(-140);
                top: rem(500);
                background: url("#{$path-img}/ingredients/grains-raisin-blanc.png");
                background-size: contain;
                background-repeat: no-repeat;
            }

            &__title {
                margin-bottom: 0;
            }
        }

        // 3 articles latéraux
        .article--aside {
            .card__img {
                @include full-size($card-default-desktop);

                img {
                    @include full-size($card-default-desktop);
                }
            }
        }
    }
}

@include media-breakpoint-up(xl) {

    .well-eating--blog {

        .block__content {
            align-items: flex-start;
            justify-content: space-evenly;
        }

        // 3 articles latéraux
        .article--aside {
            .card.article {
                max-width: none;
                margin: 0 0 rem(30) 0;
            }

            .card__link {
                flex-direction: row;
                display: flex;
                max-width: 590px;
                align-items: flex-start;
            }

            .card__img {
                @include full-size($card-well-eating);
                margin: 0;

                // overlay rouge
                &:after {
                    @include full-size($card-well-eating);
                    background-position: 0 $card-well-eating;
                }

                img {
                    @include full-size($card-well-eating);
                }
            }

            .card__content {
                height: $card-well-eating;
                margin-left: 40px;
                flex: 0 0 365px;
            }

            .card__title {
                margin-bottom: 10px;
                line-height: rem(28);
            }

            $font-size: rem(20);
            $line-height: 1.3;
            $line-to-show: 2;

            .card__excerpt {
                margin-bottom: 0;
                height: $font-size*$line-height*$line-to-show;
                overflow: hidden;
            }
        }
    }

}

// ==========================================================================
// Bloc 'En savoir plus sur nos produits'
// ==========================================================================

.learn-more {
    font-family: $barlow-regular;
    max-width: rem(840);
    padding: rem(30) rem(20);

    .block__text {
        .more {
            display: none;
        }
    }

    .block__link {
        display: none;

        &.show {
            display: block;
        }
    }
}

/*----------  Media Queries  ----------*/

@include media-breakpoint-up(md) {

    .learn-more {
        position: relative;
        padding: rem(50) rem(135) rem(60) rem(100);

        &:before {
            content: '';
            position: absolute;
            top: 25%;
            left: -35%;
            width: rem(210);
            height: rem(340);

            background-image: url("#{$path-img}/ingredients/cerneaux-noix-2.png");
            background-repeat: no-repeat;
            background-size: contain;
            background-color: transparent;
        }

        .block__content {
            font-size: rem(20);
            line-height: 1.3;
        }
    }

}

@include media-breakpoint-up(xl) {
    .learn-more {
        &:before {
            left: -25%;
        }
    }
}

@include media-breakpoint-up(xxl) {
    .learn-more {
        &:before {
            left: -40%;
        }
    }
}

// ==========================================================================
// Bloc 'L'info nutrition'
// ==========================================================================

.nutri-info {
    .block__content {
        flex-direction: column;
    }
}

/*----------  Media Queries  ----------*/

@include media-breakpoint-up(md) {

    .nutri-info {
        .block__wrapper {
            display: flex;
        }

        .block__content {
            align-items: flex-start;
            flex-direction: row;
            margin-left: rem(35);
        }

        .block__content-wrapper {
            margin-top: rem(100);
        }

        .block__img {
            flex: 0 0 rem(175);
        }

        .block__text {
            margin: auto 0 auto rem(35);
        }
    }

}

// ==========================================================================
// Bloc 'Le petit plus par Soignon'
// ==========================================================================

.soignon-tips {

    .block__content {
        flex-direction: column;
        align-items: flex-start;
        max-width: rem(275);
    }

    .block__title {
        margin-bottom: rem(25);
    }

}

@include media-breakpoint-up(md) {

    .soignon-tips {
        .block__title {
            margin-bottom: rem(-7);
        }
    }
}


// ==========================================================================
// Bloc 'Page edito'
// ==========================================================================

.block--edito {

    padding: rem(40) 0;

    .block {
        &__title {
            font-size: rem(35);
            line-height: 1;
            color: $red-variant;
            margin: 0 $edito-padding-xs rem(15) $edito-padding-xs;
        }
    }

    .well-eating__tags {
        margin-left: $edito-padding-xs;
        margin-right: $edito-padding-xs;
    }

    // position custom block
    .wysiwyg {
        margin: rem(10) $edito-padding-xs;
    }

    .custom-block-header {
        margin-left: $edito-padding-xs;
        margin-right: $edito-padding-xs;
    }

    .custom-block-image {
        margin-left: $edito-padding-xs;
        margin-right: $edito-padding-xs;
    }

    .custom-block-image-text {
        margin-left: $edito-padding-xs;
        margin-right: $edito-padding-xs;
    }

    .custom-block-actu {
        margin-left: $edito-padding-xs;
        margin-right: $edito-padding-xs;
    }

    .custom-block-recipes {
        margin-left: $edito-padding-xs;
        margin-right: $edito-padding-xs;
    }


    .custom-block-testimony {
        margin-left: $edito-padding-xs;
        margin-right: $edito-padding-xs;
    }

    .custom-block-key-dates {
        margin-left: $edito-padding-xs;
        margin-right: $edito-padding-xs;
    }

    .custom-block-slate {
        margin-left: $edito-padding-xs;
        margin-right: $edito-padding-xs;
    }

    .custom-block-slider {
        margin-left: $edito-padding-xs;
        margin-right: $edito-padding-xs;
    }

    .custom-block-left-text {
        margin-left: rem(10);
        margin-right: rem(10);
    }

    .custom-block-video {
        margin-left: $edito-padding-xs;
        margin-right: $edito-padding-xs;
    }

    .custom-block-back-text {
        margin-left: $edito-padding-xs;
        margin-right: $edito-padding-xs;
    }
}

@include media-breakpoint-up(md) {
    .block--edito {

        padding: rem(85) 0;

        .block {
            &__title {
                margin: 0 $edito-padding-md rem(35) $edito-padding-md;
                font-size: rem(80)
            }
        }

        .well-eating__tags {
            margin-left: $edito-padding-md;
            margin-right: $edito-padding-md;
        }

        // position custom block
        .wysiwyg {
            margin: rem(10) $edito-padding-md;
        }

        .custom-block-header {
            margin-left: $edito-padding-md;
            margin-right: $edito-padding-md;
        }

        .custom-block-image {
            margin-left: $edito-padding-md;
            margin-right: $edito-padding-md;
        }

        .custom-block-image-text {
            margin-left: rem(50);
            margin-right: rem(50);
        }

        .custom-block-actu {
            margin-left: $edito-padding-md;
            margin-right: $edito-padding-md;
        }

        .custom-block-recipes {
            margin-left: rem(50);
            margin-right: rem(50);
        }

        .custom-block-testimony {
            margin-left: rem(50);
            margin-right: rem(50);
        }

        .custom-block-key-dates {
            margin-left: $edito-padding-md;
            margin-right: $edito-padding-md;
        }

        .custom-block-slate {
            margin-left: rem(60);
            margin-right: rem(60);
        }

        .custom-block-slider {
            margin-left: rem(60);
            margin-right: rem(60);
        }

        .custom-block-left-text {
            margin-left: rem(60);
            margin-right: rem(60);
        }

        .custom-block-video {
            margin-left: $edito-padding-md;
            margin-right: $edito-padding-md;
        }

        .custom-block-back-text {
            margin-left: $edito-padding-md;
            margin-right: $edito-padding-md;
        }
    }
}

// ==========================================================================
// Bloc edito - Page résultat de recherche
// ==========================================================================

/*----------  Media Queries  ----------*/

@include media-breakpoint-up(sm) {
    .edito-results {
        padding: rem(25) rem(25) rem(25) rem(80);
    }
}

@include media-breakpoint-up(xl) {
    .edito-results {
        padding: 0 rem(280) rem(70) rem(140);
    }
}

// ==========================================================================
// Bloc 'Vous n'avez pas trouvé de réponse à votre question ?' - FAQ
// ==========================================================================

.find-no-answer {

    .card__img {
        img {
            @include full-size($card-default-mobile);
        }
    }
}

@include media-breakpoint-up(md) {

    .find-no-answer {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding-top: rem(40);
        padding-left: rem(20);

        .card__img {
            flex: 0 0 auto;
        }

        .card__content {
            margin-left: rem(50);
        }
    }
}


// ==========================================================================
// Bloc '120 ans de savoir-faire' - page Notre Histoire
// ==========================================================================

.block {
    &--our-history {
        padding: rem(30) rem(35) rem(40);
        transform: rotate(-1deg) translateY(-7px);
    }
}

@include media-breakpoint-up(md) {

    .block {
        &--our-history {
            transform: rotate(-1deg) translateY(-13px);
        }
    }
}
