/**
 *
 * STRUCTURE
 * Styles dédiés aux ensembles structurant une page
 *
 * Regroupe les styles globaux de chaque élément de structure : header (`_header.scss`)
 * contenu (`_content.scss`), et footer (`_footer.scss`).
 *
 */

// ==========================================================================
// Content
// ==========================================================================

@import 'content';

// ==========================================================================
// Footer
// ==========================================================================

@import 'footer';

// ==========================================================================
// Header
// ==========================================================================

@import 'header';

// ==========================================================================
// Navigation
// ==========================================================================

@import 'navigation';