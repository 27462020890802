// ==========================================================================
// Home nouveauté
// ==========================================================================

.home-news {
  &__title {
    margin: rem(0) rem(25) rem(20) rem(25);
    font-family: $compotes-bold;
    font-size: rem(50);
  }

  &--no-recipe {
      padding-top: rem(50);
  }
}

@include media-breakpoint-up(md) {
  .home-news{
    position: relative;

    &--no-recipe {
        padding-top: rem(80);
    }

    &__title {
      font-size: rem(60);
      line-height: rem(60);
      background-color: $white;
      background-image: url("#{$path-img}/fond_texture.png");
    }

    &__list {

      position: relative;
      margin: 0 auto;

      .vignette {
        &--portrait {
          width: auto;
          .vignette {

            &__image-container {
              transform: none;
            }

            &__image {
              max-width: rem(735); max-height: rem(500);
              transform: rotate(-3deg);
              background: $white;
              object-fit: contain;
            }

            &__presentation {
              width: rem(200);
              font-size: rem(28);
              line-height: rem(31);
              left: rem(-220);
              top: rem(325);
              text-align: left;
            }
          }
        }
      }
    }
  }
}


@include media-breakpoint-up(lg) {
  .home-news{

    &__basilic {
      width: rem(172);
      height: rem(118);
      background: url("#{$path-img}/ingredients/jeune-pousse-3.png");
      position: absolute;
      top: rem(390);
      left: -20%;
      background-size: contain;
      background-repeat: no-repeat;
    }

    &__noisette {
      content: "";
      position: absolute;
      width: rem(286);
      height: rem(865);
      right: -35%;
      top: 0;
      background: url("#{$path-img}/ingredients/noisettes-decortiquees-unified.png");
      background-size: contain;
      background-repeat: no-repeat;
    }

    &__title {
      font-size: rem(100);
      line-height: rem(100);
    }

    &__list {
      width: rem(700);
      .vignette {
        &--portrait {
          .vignette {
            &__presentation {
              position: absolute;
            }
          }
        }
      }
    }
    &__leg {
      position: absolute;
      width: rem(445); height: rem(379);
      right: rem(-200); bottom: rem(-190);

      background-image: url("#{$path-img}/ingredients/patte-droite.png");
      background-repeat: no-repeat;
    }
  }
}

@include media-breakpoint-up(xl) {
  .home-news {
    &__list { width: rem(900); }
  }
}


@include media-breakpoint-up(xxl) {
    .home-news {
        &:before { left: -10%; }
        &:after { right: -25%; }
    }
}
