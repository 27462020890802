// ==========================================================================
// Products
// ==========================================================================

.product-banner {
    position: relative;
    margin-bottom: rem(40);
    z-index: 10;
}

.product-banner__image {
    position: absolute;
    bottom: -20%;
    max-width: rem(215);
    max-height: rem(200);
    object-fit: contain;
}

.product__hero {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
}

.product__buttons {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-grow: 1;
}




// ==========================================================================
// Media Queries
// ==========================================================================


@include media-breakpoint-up(md) {

    .product-banner { margin-bottom: 0; }

    .product-banner__image {
        max-width: rem(390);
        max-height: rem(365);
    }

    .product__hero {
        flex-wrap: initial;

        .page-header__description { width: 75%; }
    }

    .product__buttons { align-items: flex-end; }

    .product-infos {
        display: grid;
        grid-template: auto auto auto / 33% 50%;
        grid-gap: 0 10%;
        margin-left: 10%;

        &__nutri-values {
            grid-row: span 4;
        }
    }

}
