.c-modal-redirect {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 10000;
    display: none;
    align-items: center;
    justify-content: center;

    &.active {
        display: flex;
    }

    &__background {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: rgba($white, .5);
    }

    &__container {
        width: rem(492);
        max-width: 90%;
        background: $white;
        display: flex;
        flex-direction: column;
        box-shadow: 0 rem(2) rem(23) rem(1) rgba(0, 0, 0, 0.2);
        position: relative;
    }

    &__close-btn {
        padding: 0;
        border: none;
        background: none;
        width: rem(21);
        height: auto;
        margin-top: rem(24);
        margin-right: rem(22);
        margin-left: auto;
    }

    &__body {
        margin-top: rem(10)
    }

    &__content {
        margin-bottom: rem(20);
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    &__text {
        font-weight: bold;
        font-size: rem(24);
        line-height: rem(28);
        text-align: center;
        max-width: rem(300);
    }

    &__img {
        width: rem(160);
        max-width: 35%;
        margin-bottom: 0;
        margin-left: auto;

        img {
            display: block;
            width: 100%;
            height: auto;
        }
    }

    @include media-breakpoint-up(md) {

        &__content {
            margin-left: rem(34);
            align-items: flex-start;
            justify-content: flex-start;
            margin-right: rem(17);
            width: calc(100% - #{rem(210)}); // 160 (taille de l'image) - 34 (margin-left) - 17 (margin-right)
        }

        &__text {
            text-align: left;
        }

        &__img {
            margin-top: rem(-50);
        }
    }
}
