// ==========================================================================
// Steps
// ==========================================================================


@include media-breakpoint-up(md) {

  .steps { padding-left: rem(100); padding-bottom: rem(80); }

  .steps__list { margin-left: rem(-15); }

  .step {
    display: flex;
    align-items: center;
    margin-bottom: rem(10);
  }

  .step__index { flex: 0 0 auto; }

  .step__text {
    margin-bottom: 0; margin-left: rem(45); margin-top: rem(15);
    font-size: rem(22);

      + p {
          margin-top: 0;
          margin-bottom: 0;
      }
  }
}
