#ground {
  width: 100%;
  position: absolute;
  top:  0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  overflow: hidden;
}

.hoof {
  background: url("#{$path-img}/hoof.png") center center no-repeat;
  -webkit-background-size: cover;
  background-size: cover;
  width: 12px;
  height: 12px;
  position: absolute;
  opacity: 0.2;

  &.hoof--active {
    opacity: 1;
  }
}
