// ==========================================================================
// Pagination
// ==========================================================================

.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: rem(40); padding-bottom: rem(40);
  z-index: 0;
}
.page-item {
  color: $black;
  font-family: $barlow-regular;
  line-height: 1.3;
  font-size: rem(20);
  font-stretch: condensed;
  font-weight: 300;
  text-align: center;
}

.page-item--previous { padding-right: rem(24); }

.page-item--next { padding-left: rem(24); }