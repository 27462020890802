// ==========================================================================
// Filters
// ==========================================================================


.filters {
    margin-top: rem(40);
    margin-bottom: rem(50);

    &.box-shadow {
        box-shadow: 0 rem(-3) rem(10) 0 rgba(34, 34, 34, 0.2);

        &:after {
            content: '';
            position: absolute;
            top: 0;
            left: rem(2);
            box-shadow: rem(-4) rem(1) rem(10) rem(4) rgba(0, 0, 0, 0.1);
            height: 60%;
            width: 0;
            transform: rotate(-4deg);
        }
    }

    // bouton dropdown
    .dropdown-toggle {
        position: relative;
        z-index: 110;
        padding-right: 25px;

        color: $black;
        font-family: $barlow-semibold;
        font-size: rem(20);
        text-transform: initial;

        &:hover, &:focus { color: $black; }

        &:after {
            content: '';
            position: absolute;
            top: 60%;
            height: rem(22); width: rem(22);
            margin-left: unset;

            transform: translateY(-50%);
            background-image: url("#{$path-img}/arrow_black.svg");
            background-repeat: no-repeat;
            background-size: contain;
            background-position: center center;
            border: none;
            vertical-align: unset;
        }
    }

    .dropdown-menu {
        top: rem(-40) !important;
        padding-top: rem(50);

        z-index: 100;
        border: none;
        border-radius: unset;
        text-align: right;
        box-shadow: 0 rem(-3) rem(10) 0 rgba(0, 0, 0, 0.1);
    }

    // hacks nécessaires pour que la largeur du bloc dropdown soit calée sur celle du bouton toggle
    &--products { .dropdown-menu { min-width: rem(310); } }
    &--recipes { .dropdown-menu { min-width: rem(205); } }

    .dropdown-item {
        color: $black;
        font-family: $barlow-regular;
        font-size: rem(20);
        line-height: 1.3;

        &:hover, &:focus {
            background-color: $light-red;
            color: $black;
        }

        &.active, &:active {
            color: $white;
            background-color: $primary;
        }

    }

    /*----------  Filtres de la FAQ  ----------*/
    &--faq {
        margin-top: 0; margin-bottom: rem(25);

        .dropdown { text-align: center; }
        .dropdown-menu { right: 0; }
    }

}

.subcategory__item {

    /* container */
    position: relative;
    display: inline-block;
    padding-left: rem(30);
    margin-bottom: rem(10);
    margin-right: rem(6);

    font-size: rem(20);
    cursor: pointer;
    user-select: none;

    &.active {
        .subcategory__checkmark {
            background-color: $primary;
        }
    }

    /* masque la checkbox navigateur */
    .subcategory__label {
        position: relative;

        color: $black;
        font-family: $barlow-regular;
        font-size: rem(20);
        line-height: 1.3;

        &:before {
            position: absolute;
            top: 50%;
            left: 0;
            height: rem(20);
            width: 100%;

            opacity: 0;
            transform: translateY(-50%);
            cursor: pointer;
        }
    }

    /* crée un bouton radio custom */
    .subcategory__checkmark {
        position: absolute;
        top: 50%;
        left: 0;
        height: rem(20);
        width: rem(20);
        border-radius: 50%;
        border: rem(1) solid $grey;
        transform: translateY(-50%);

        /* crée l'indicateur (caché si pas checké) */
        &:after {
            content: "";
            position: absolute;
            display: none;
        }

    }
}

.subcategories {
    //&--product {
      display: none;
      padding-left: rem(25); padding-right: 0;

      &.active {
          display: block;
          margin-top: rem(40);
      }

      .nav-link { padding-top: 0; padding-bottom: 0; }

      .nav-pills .nav-link.active,
      .nav-pills .show > .nav-link { background-color: transparent; }
    //}

    /*----------  Sous-catégories de la FAQ  ----------*/
    &--faq {
        display: block;
        padding-left: 0;

        .nav {
            flex-direction: column;
        }
    }
}


/*----------  Media Queries  ----------*/

@include media-breakpoint-up(md) {

    .filters {
        margin-top: rem(50); margin-bottom: rem(80);

        // hacks nécessaires pour que la largeur du bloc dropdown soit calée sur celle du bouton toggle
        &--products { .dropdown-menu { min-width: rem(345); } }

        .dropdown-toggle { font-size: rem(22); }

        .product-subcategories { padding-left: rem(40); padding-right: rem(40); }

        /*----------  Filtres de la FAQ  ----------*/
        &--faq { margin-top: 0; margin-bottom: rem(20); }
    }

}
