// ==========================================================================
// PAge contact
// ==========================================================================


@include media-breakpoint-up(md) {
  .contact-index {

    .page-header {
      width:100%;
      max-width: 100%;
      padding-left: rem(65);
    }
  }
}


@include media-breakpoint-up(lg) {
  .contact-index {

    .illu__container {

        &:after {
            top: 50%; right: 0;
            width: rem(440); height: rem(440);

            @include illu-positioning("#{$path-img}/ingredients/figues-basilic-composition.png", contain);
            box-shadow: none;
            transform: translate(-20%);
        }

    }
  }
}
