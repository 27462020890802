/**
 *
 * ELEMENTS
 * Styles dédiés aux éléments spécifiques
 *
 * Certains éléments nécessitent un code spécifique qui ne peut être ré-utilisable
 * au travers du projet (à l'inverse des modules). Il s'agit de styles fortement
 * dépendants du contexte et du contenu de l'élément.
 *
 * Exemple : slider home, payment form, weather widget...
 *
 */

// ==========================================================================
// Tableau valeurs nutritionnelles
// ==========================================================================

@import 'nutrivalues-table';

// ==========================================================================
// Notation recette
// ==========================================================================

@import 'rating';

// ==========================================================================
// Bloc informations nutritionnelles
// ==========================================================================

@import 'nutritional-infos-block';

// ==========================================================================
// Section home recipe
// ==========================================================================

@import 'home-recipe';

// ==========================================================================
// Section home recipe
// ==========================================================================

@import 'home-news';

// ==========================================================================
// Section home completely goat
// ==========================================================================

@import 'home-completely-goat';

// ==========================================================================
// Section home 120
// ==========================================================================

@import 'home-120';

// ==========================================================================
// Section home footer
// ==========================================================================

@import 'home-footer';

// ==========================================================================
// Section home footer
// ==========================================================================

@import 'home-coming-soon';
