// ==========================================================================
// Colors
// ==========================================================================

$red: #ec1c25;
$light-red: rgba(225, 31, 29, 0.2);
$red-variant: #e11f1d;
$black: #222222;
$light-black: #1e1e1e;
$dark-grey: rgba(30, 30, 30, 0.5);
$grey: rgba(30, 30, 30, 0.15);
$light-grey: #f8f8f8;
$white: #ffffff;
$grey-2: #969696;

$primary: $red;


$colors: ();
$colors: (
        'primary': $red,
        'red': $red,
        'light-red': $light-red,
        'red-variant': $red-variant,
        'black': $black,
        'light-black': $light-black,
        'light-grey': $light-grey,
        'white': $white,
);

/**
 * Automatically generate text color utilities class for each of our
 * defined contrib colors.
 *
 * <div class="u-color--green">
 */
@each $key, $value in $colors {
  .color--#{$key} { color: $value; }
  .bg--#{$key} { background-color: $value; }
}
