// ==========================================================================
// Custom block video
// ==========================================================================

.custom-block-video {
	margin-bottom: rem(40);
}



@include media-breakpoint-up(md) {
	.custom-block-video {

	}
}