// ==========================================================================
// Bloc informations nutritionnelles
// ==========================================================================

.product-info {
  margin-bottom: rem(50);

  /* style inline différent */
  &:not(.packaging) {
    h4 { margin-bottom: rem(20); }
  }
}

/*----------  Ingrédients produit  ----------*/

.ingredients__list { font-size: rem(18); }


/*----------  Format produit  ----------*/

.packaging {
  display: flex;
  align-items: center;
}

.product-format {
  display: flex;
  align-items: center;
  margin-left: rem(45);

  font-size: rem(25);
  font-family: $compotes-bold;
  line-height: 1.8;

  .circle { margin-left: rem(10); }
}

/*----------  Logos labels produit  ----------*/

.product-labels {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.product-label {
  display: flex;
  align-items: center;
  margin-bottom: rem(20);
}

// logo recyclage
.logo-recycle {
  font-family: $barlow-regular;
  font-size: rem(14);
  line-height: normal;
}

.product-label__logo {
  flex: 0 0 auto;
  margin-bottom: rem(23);
  margin-right: rem(25);
  max-height: rem(45);

  // logo médaille
  &.logo-medal { max-height: rem(75); }
}

.product-label__container {
    width: 100%;

    &--kosher,
    &--halal {
        width: auto;
    }
}


// ==========================================================================
// Media Queries
// ==========================================================================

@include media-breakpoint-up(md) {

  .ingredients__list { font-size: rem(20); }

}
