// ==========================================================================
// Video
// ==========================================================================

/*----------  Bouton player  ----------*/
.content-video {
  position: relative;

  // btn player
  &:before {
    content: "";
    position: absolute;
    @include sizing(rem(60));
    max-width: rem(60); max-height: rem(60);
    top: 50%; left: 50%;

    transform: translate(-50%, -50%);
    z-index: 10;
    background-image: url("#{$path-img}/icon_player.svg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center center;
  }
}
