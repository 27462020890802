// ==========================================================================
// Buttons
// ==========================================================================

.btn, .btn:visited {
	color: $white;
	border-radius: rem(25);
	white-space: nowrap;

	&:hover, &:focus {
		color: $white;
		box-shadow: none;
	}

	&.disabled, &:disabled { opacity: 0.15; }
}

// on card hover, buttons have same behavior
a, a:visited {
	&:hover, &:focus {

		.btn--primary, .btn--primary:visited { background-image: radial-gradient(circle at 49% 50%, #ed1c25, #c41f25); }
	}
}

/*----------  Variantes de style  ----------*/

// boutons rouges
.btn--primary {
	padding: rem(5) rem(45);

	background-color: $primary;
	text-transform: uppercase;
	font-family: $barlow-semibold;
	font-size: rem(18);
	line-height: 1.55;

	&:hover, &:focus { background-image: radial-gradient(circle at 49% 50%, $red, #c41f25); }
}

// boutons noirs
.btn--black {
	background-color: $black;

	&:hover, &:focus {
		background-image: radial-gradient(circle at 49% 50%, #2c2c2c, $black);
	}
}

// boutons inactifs
.btn--inactive { background-color: $light-red; }

// boutons arrows
.btn--prev, .btn--next { flex: 0 0 rem(30); }
.btn--prev {
	img { transform: translate(-50%, -50%) rotate(180deg); }
}

/*----------  Variantes de taille  ----------*/
.btn--large {
	padding: rem(9) rem(54);
	line-height: 1.72;
}

.btn--small {
	padding: rem(5) rem(30);
	line-height: 1.72;
}

