// ==========================================================================
// Functions
// ==========================================================================

/*----------  Conversion en rem  ----------*/
// Usage : `font-size: rem(14)`

@function rem($value) {
  @return ($value / $font-size-base-px) * 1rem;
}

/*----------  Création de classes utiles pour les fonts  ----------*/
// exemple : .fs-24
$font-sizes:
  18,
  20,
  24,
  28,
  35,
  45,
  60,
  80,
  100,
;

@each $font-size in $font-sizes{
  .fs-#{$font-size} {
    font-size: $font-size#{px};
  }
}

/*----------  Création de classes utiles pour les margin et padding  ----------*/

// Helpers custom (ajout d'un tiret, ex. "m-t", pour ne pas overrider ceux de bootstrap et générer des effets de bord).
// exemples : .m-x-25 (margin horiz. de 25px), .p-y-70 (padding vertic. de 70px)
$spaces:
    0,
    5,
    10,
    15,
    20,
    25,
    30,
    35,
    40,
    45,
    50,
    55,
    60,
    65,
    70,
    75,
    80,
    85,
    90,
    95,
    100,
;
$media-queries:
    xs,
    sm,
    md,
    lg,
    xl,
    xxl
;

// margins
@each $space in $spaces{
    .m-y-#{$space} { margin-top: #{$space}px; margin-bottom: #{$space}px; }
    .m-x-#{$space} { margin-left: #{$space}px; margin-right: #{$space}px; }
    .m-t-#{$space} { margin-top: #{$space}px; }
    .m-b-#{$space} { margin-bottom: #{$space}px; }
    .m-l-#{$space} { margin-left: #{$space}px; }
    .m-r-#{$space} { margin-right: #{$space}px; }
}

// paddings
@each $space in $spaces{
    .p-y-#{$space} { padding-top: #{$space}px; padding-bottom: #{$space}px; }
    .p-x-#{$space} { padding-left: #{$space}px; padding-right: #{$space}px; }
    .p-t-#{$space} { padding-top: #{$space}px; }
    .p-b-#{$space} { padding-bottom: #{$space}px; }
    .p-l-#{$space} { padding-left: #{$space}px; }
    .p-r-#{$space} { padding-right: #{$space}px; }
}


@each $media-query in $media-queries{

    @include media-breakpoint-up(#{$media-query}){

        // margins
        @each $space in $spaces{
            .m-y-#{$media-query}-#{$space} { margin-top: #{$space}px; margin-bottom: #{$space}px; }
            .m-x-#{$media-query}-#{$space} { margin-left: #{$space}px; margin-right: #{$space}px; }
            .m-t-#{$media-query}-#{$space} { margin-top: #{$space}px; }
            .m-b-#{$media-query}-#{$space} { margin-bottom: #{$space}px; }
            .m-l-#{$media-query}-#{$space} { margin-left: #{$space}px; }
            .m-r-#{$media-query}-#{$space} { margin-right: #{$space}px; }
        }

        // paddings
        @each $space in $spaces{
            .p-y-#{$media-query}-#{$space} { padding-top: #{$space}px; padding-bottom: #{$space}px; }
            .p-x-#{$media-query}-#{$space} { padding-left: #{$space}px; padding-right: #{$space}px; }
            .p-t-#{$media-query}-#{$space} { padding-top: #{$space}px; }
            .p-b-#{$media-query}-#{$space} { padding-bottom: #{$space}px; }
            .p-l-#{$media-query}-#{$space} { padding-left: #{$space}px; }
            .p-r-#{$media-query}-#{$space} { padding-right: #{$space}px; }
        }
    }
}
