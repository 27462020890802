// ==========================================================================
// Home recette
// ==========================================================================

.home-recipe {

  margin-top: 0;
  position: relative;
  padding: rem(100) rem(0) rem(0) rem(0);

  background-image: url("#{$path-img}/ingredients/basilic-mini.png");
  background-position: right -150px bottom 30%;
  background-repeat: no-repeat;

  &__image {
    position: absolute;
    max-width: rem(215); max-height: rem(200);

    z-index: 1;
    object-fit: contain;
    transform: translateY(-50%);
  }

  &__title {
    font-size: rem(50);
    font-family: $compotes-bold;
    margin-bottom: rem(0);
  }


  &__time {

  }

  &__title-description {
    display: block;
  }

  &__list {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__vignette {
    &:first-child {
      margin-bottom: 0;

      .vignette {
        &__image-container {
          width: rem(380);
          margin-bottom: 0;
          transform: translateX(rem(-115));

          .vignette__image { width: rem(380); }
        }
      }
    }

    &:not(:first-child) {
        .vignette__image-container {
            @include full-size($card-recipe-mobile);

            img { @include full-size($card-recipe-mobile); }
        }
    }
  }
}

@include media-breakpoint-up(sm) {
  .home-recipe {
      &__vignette {
          &:first-child {
              .vignette {
                  &__image-container {
                      transform: translateX(rem(-50));
                  }
              }
          }
      }
  }
}

@include media-breakpoint-up(md) {
  .home-recipe {
    padding-top: 0;
    background-image: none;

    &__image {
        height: rem(365);
        max-width: rem(645); max-height: rem(365);
    }

    &__title-container {
      margin-left: 60%;
    }

    &__container {
      display: flex;
      justify-content: center;
    }

    &__title {
      position: relative;
      top: rem(100);
      font-size: rem(60);
      line-height: rem(60);
    }

    &__list {
      display: inline-flex;
      position: relative;
      align-items: flex-start;
      top: rem(-20);

      &:before {
        content: "";
        width: rem(150);
        height: rem(170);
        background-image: url("#{$path-img}/ingredients/basilic-home.png");
        background-size: cover;
        position:absolute;
        left: rem(-115);
        z-index: 1;
      }
    }

    &__vignette {


      .vignette {
        &__title {
          width: rem(250);
        }
      }

      &:first-child {
        width: auto;
        transform: translateY(rem(-20));

        .vignette {
          &__image-container {
            width: rem(680);

            .vignette__image { width: rem(680);  }
          }

          &__presentation {
            position: relative;
            top: rem(200);
            align-self: flex-start;
          }

          &__item {
            display: flex;
          }
        }
      }

      &:nth-child(2) {
        margin-top: 13%;
        margin-right: rem(80);
        .vignette {
          &__image-container {
            transform: rotate(3deg);
          }
        }
      }

      &:last-child {
        position: absolute;
        right: 0;
        bottom: rem(70);
        z-index: 1;

        .vignette {
          &__image-container {
            transform: rotate(-3deg);
          }
        }
      }

      &:not(:first-child) {
        .vignette__image-container {
            @include full-size($card-recipe-desktop);

            img { @include full-size($card-recipe-desktop); }
        }
      }

    }
  }
}

@include media-breakpoint-up(xl) {
  .home-recipe {
    &__title {
      font-size: rem(100);
      line-height: rem(100);
      max-width: rem(525);
    }

    &__list {
      flex-direction: row;
    }

    &__vignette {

      &:first-child {
        order: 1;

        &:before {
          position: absolute;
          top: rem(208);
          right: rem(-100);
          width: rem(134);
          height: rem(182);
          content: "";
          background-image: url("#{$path-img}/ingredients/basilic-mini.png");
          background-repeat: no-repeat;
          background-size: contain;
        }
      }

      &:last-child {
        position: absolute;
        bottom: 0;
        transform: translateY(50%);
      }
    }
  }
}
