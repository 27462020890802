// ==========================================================================
// Search
// ==========================================================================

.display-search {
  display: none;
  padding: 0;

  background: transparent;
  border: 0;
  cursor: pointer;

  &:focus { outline: 0; }
}

.search-box { display: list-item; }

.search-box__submit {
  background: transparent;
  border: 0;
}

.search-box__input {
  font-family: $barlow-light;
  font-size: rem(20);
  color: $black;
  border: none;
  border-radius: 0;

  &:focus {
    box-shadow: none;
    border-bottom: rem(1) solid $grey;
    border-radius: 0;
  }
}

@include media-breakpoint-up(md) {

  .display-search { display: inline-block; }
  .search-box { display: none; }

  // Si menu de la home
  .is-home, .is-header-transparent {
    .display-search {
      svg path { fill: $white; }
    }

    .nav-primary {
      &:hover, &:focus {

        .display-search {
          svg path { fill: $black; }
        }
      }
    }
  }
}
