/**
 *
 * VIEWS
 * Styles dédiés à une page / rubrique.
 *
 * Regroupe principalement des propriétés de positionnement(margin, padding)
 * et de dimensions (width, height). Si des styles doivent être présents sur plusieurs
 * pages, plutôt les placer dans `modules` ou `elements`
 *
 */

// ==========================================================================
// Home
// ==========================================================================

@import '404';
@import 'block-page-show';
@import 'contact';
@import 'edito';
@import 'faq-index';
@import 'home';
@import 'list-page';
@import 'our-history';
@import 'product_category-list';
@import 'recipe_category-list';
@import 'recipe_show';
@import 'sitemap';
@import 'search-index';
@import 'well-eating-show';
