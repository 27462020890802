// ==========================================================================
// Cards
// ==========================================================================


/*----------  Global  ----------*/

.card {
  max-width: $card-default-mobile;
  margin: 0 auto rem(60);

  z-index: 99;
  overflow: hidden;

  background: transparent;
  border: none;
  border-radius: initial;
  font-family: $barlow-regular;
}

.card__link {

  &:hover, &:focus {
    .card__title {
      background-image: linear-gradient(180deg, $primary, $primary);
      background-position: 0 0;
      color: $white;
    }
  }
}

.card__img {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto rem(24);
  @include full-size($card-default-mobile);
  overflow: hidden;
  position: relative;
}

.card__content {
  position: relative;
  display: flex;
  justify-content: space-between;

  color: $black;
}

.card__badge {
  position: absolute;
  top: 0; left: 0;

  transform: translateY(calc(-100% - 10px));
}

.card__title-wrapper, .card__excerpt { max-width: 85%; }

.card__title {
  position: relative;
  display: inline;
  padding: 0;

  z-index: 1;
  background-position: 0 4rem;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  transition: all .3s ease-out;

  font-family: $barlow-semibold;
  font-size: rem(24);
  line-height: 1.17;
  color: $black;
  text-transform: none;
}

.card__btn {
  position: absolute;
  top: 0; right: 0;
}

.card__excerpt {
  margin-top: rem(10);

  color: $black;
  font-size: rem(18);
  line-height: 1.33;
  font-weight: normal;
}

.card--fluid {
    max-width: rem(270);
    margin: 0 auto 3.75rem;

  .card {
    &__img {
      width: 100%;
      height: auto;
    }

    &__title-wrapper {
      max-width: none;
    }
  }
}


// ==========================================================================
// Variantes de style
// ==========================================================================

// Produits
.card.product {
    .card__img {
        height: auto;
        max-width: unset; max-height: unset;
    }
}

/*----------  Produits suggérés  ----------*/
.cross-product {
    margin-bottom: rem(35);

    &.card.product {
        .card__img {
            @include full-size($card-default-mobile);
        }
    }
}

/*----------  Recette du mois  ----------*/
.recipe--highlight {
  padding: rem(10);

    .card__img--highlight {
        @include full-size($card-default-xs);

        img { @include full-size($card-default-xs); }
    }
}

/*----------  Produit du mois  ----------*/
.product--highlight {
  padding: rem(10);

  .card__img {
    width: $card-default-xs; height: auto;
    max-height: rem(200);
  }
}

/*----------  Article  ----------*/
.article {
  margin-bottom: rem(35);

  .card__img {
    @include full-size($card-default-mobile);

    // red overlay
    &:after {
      content: '';
      position: absolute;

      z-index: 1;
      background-size: 100% 100%;
      background-repeat: no-repeat;
      transition: all .3s ease-out;
      width: 100%;
      height: 100%;
    }
  }

  // hover
  .card__link {
    &:hover, &:focus {

      // red overlay animation
      .card__img {
        &:after {
          background-image: linear-gradient(180deg, rgba(225, 31, 29, 0.3), rgba(225, 31, 29, 0.3));
          background-position: 0 0;
        }
      }
    }
  }

  .card__title-wrapper, .card__excerpt { max-width: 94%; width: 94%; }
  .card__excerpt { margin-bottom: rem(10); }
}

/*----------  Article (vidéo)  ----------*/
.article--video {

  .card__img {

    // btn player
    &:before {
      content: "";
      position: absolute;
      @include sizing(rem(60));
      max-width: rem(60);
      max-height: rem(60);

      z-index: 1000;
      background-image: url("#{$path-img}/icon_player.svg");
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
    }
  }
}

/*----------  Articles suggérés (page liste recettes)  ----------*/
.cross-articles {
  max-width: $card-default-desktop;
  margin-left: auto; margin-right: auto;

  .card__img {
    @include full-size($cross-article-img);
    margin-top: rem(15);

    img { @include full-size($cross-article-img); }
  }

  .card__content { margin-bottom: rem(50); }

  .card__link {
    &:hover, &:focus {
      .card__img:after { background-image: none; }
    }
  }
}

/*----------  Recette (liste)  ----------*/
.recipe {

  &.card, .card__img { overflow: visible; }
  .card__img {
    @include full-size($card-recipe-mobile);

    img { @include full-size($card-recipe-mobile); }
  }
}

/*----------  Recettes suggérées  ----------*/
.cross-recipes {
  margin-bottom: rem(20);

  &.card { max-width: $card-recipe-xs; overflow: visible; }

  .card__img {
    @include full-size($card-recipe-xs);
    margin-top: rem(15);
    overflow: visible;

    img { @include full-size($card-recipe-xs); }
  }
}

/*----------  Card 'Toutes nos recettes'  ----------*/
.all-recipes {
  max-width: rem(285);
  display: flex;
  margin: rem(20) auto rem(50);
  padding: 0 rem(10) rem(20);

  .card {
    max-width: none;
    margin-bottom: rem(35);
    align-items: center;
    overflow: visible;
  }

  .card__img {
    margin: 0;
    @include full-size($card-all-recipes);

    overflow: visible;

    img { @include full-size($card-all-recipes); }
  }

  .card__content {
    flex-direction: column;
    padding-top: rem(10);
  }

  .card__title-wrapper {
      padding-left: rem(10);
  }

  // blocs inclinés uniquement
  &.rotate--left {
    .card__img { transform: rotate(-1deg); }
  }
  &.rotate--right {
    .card__img { transform: rotate(1deg) translateX(rem(2)); }
  }

  &.box-shadow { padding: 0; }
}


// ==========================================================================
// Media Queries
// ==========================================================================


@include media-breakpoint-up(md) {

  /*----------  Global  ----------*/

  .card {
    max-width: $card-default-desktop;
    margin: 0 auto rem(40);
  }

  .card__title {
    font-size: rem(28);
    line-height: 1.11;
  }

  .card__excerpt {
    margin-bottom: rem(30);

    font-size: rem(20);
    line-height: 1.3;
    font-weight: normal;
  }

  // Produits
  .card.product {
      margin: 0 auto rem(60);

      .card__img { height: $card-default-mobile; }
  }

  /*----------  Variantes de style  ----------*/

  // Article
  .article {
    margin-bottom: rem(45);

    .card__img {
      @include full-size($card-default-desktop);

      // red overlay
      &:after {
        width: 100%;
        height: 100%;
      }

      img { @include full-size($card-default-desktop); }
    }
  }

  // Recette (liste)
  .recipe {
    .card__img {
      @include full-size($card-recipe-desktop);

      img { @include full-size($card-recipe-desktop); }
    }
  }


  /*----------  Produits suggérés  ----------*/
  .cross-product { margin-bottom: rem(50); }

  /*----------  Recettes suggérées  ----------*/
  .cross-recipes {
      margin-bottom: rem(50);

      &.card { max-width: $card-recipe-desktop; }

      .card__img {
          @include full-size($card-recipe-desktop);

          img { @include full-size($card-recipe-desktop); }
      }
  }

  // Card 'Toutes nos recettes'
  .all-recipes {
    max-width: none;
    padding: unset;

    .card {
      flex-direction: row;
      align-items: center;
      margin-bottom: 0;
      padding-bottom: rem(5); padding-right: rem(100);
    }

    .card__img {
      margin: 0;
      @include full-size($card-all-recipes);

      img { @include full-size($card-all-recipes); }
    }

    .card__title-wrapper {
        max-width: 100%;
        padding-left: unset;
    }

    .card__content { padding: 0 0 0 rem(60); }

    .card__excerpt {
        font-size: rem(28);
        max-width: 100%;
    }
  }

  /*----------  Produit du mois  ----------*/
  .product--highlight {

      .card__img { width: $card-default-desktop; max-height: rem(295); }
  }

    /*----------  Recette du mois  ----------*/
    .recipe--highlight {

        .card__img--highlight {
            @include full-size(400px !important);

            img { @include full-size(400px !important); }
        }
    }

  /*----------  Variantes de taille  ----------*/

  .card__img--large {
    @include full-size($card-default-desktop-large !important);

    img { @include full-size($card-default-desktop-large !important); }
  }

  // card + grande
  .card--large {
    max-width: $card-default-desktop-large;

    .card__img {
      @include full-size($card-default-desktop-large);

      // red overlay
      &:after {
        @include sizing($card-default-desktop-large);
        background-position: 0 $card-default-desktop-large;
      }

      img { @include full-size($card-default-desktop-large); }
    }

    .card__content { max-width: rem(363); }

  }

  // card + large
  .card--wide {
    max-width: $card-default-desktop-wide;
    padding: rem(70) rem(45);

    .card__content, .card__headline, .card__excerpt { max-width: rem(235); }

    .card__headline { margin-bottom: rem(40); }

    .card__img {
      position: absolute;
      top: 50%; right: 0;
      width: auto;

      transform: translateY(-50%);
    }

    // Recette du mois
    &.recipe--highlight {
      width: auto; max-width: rem(760);
      padding: rem(65) rem(10) rem(70) rem(25);

      .card__img { transform: translateY(-50%) rotate(-1deg); }
      .card__content { min-height: rem(185); }
      .card__headline { max-width: 30%; }
    }
  }
}

@include media-breakpoint-up(lg) {

    /*----------  Recette du mois  ----------*/
    .recipe--highlight {

        .card__img--highlight {
            @include full-size($card-recipe-highlight !important);

            img { @include full-size($card-recipe-highlight !important); }
        }
    }
}

@include media-breakpoint-up(xl) {

  // Card 'Toutes nos recettes'
  .all-recipes { max-width: 90%; }
  .block.all-recipes { max-width: 100%; }

  .card--fluid {
      max-width: none;
      margin: auto;
  }
}

// Breakpoint spécifique pour un affichage propre de la 'Recette du mois'
@media (min-width: 1200px) and (max-width: 1340px) {

    .card--wide {
        /*----------  Recette du mois  ----------*/
        &.recipe--highlight {
            padding: rem(25) rem(10) rem(25) rem(25);

            .card__headline { max-width: 35%; }
            .card__img--highlight {
                @include full-size($card-recipe-highlight-xl !important);

                img { @include full-size($card-recipe-highlight-xl !important); }
            }
        }
    }
}

// Breakpoint spécifique pour un affichage propre du 'Produit du mois'
@media (min-width: 1340px) {

    // card + large
    .card--wide {
        @include sizing($card-default-desktop-xl-wide, auto);
        max-width: $card-default-desktop-xl-wide;
        padding: rem(100) rem(45);

        .card__headline { max-width: rem(320); }
        .card__content, .card__excerpt { max-width: rem(245); }
    }

}
