// ==========================================================================
// Badges
// ==========================================================================

// badge produit
.badge {
  display: inline-block;
  margin-bottom: rem(10);
  padding: rem(5) rem(22);

  background-color: $primary;
  color: $white;
  font-family: $barlow-regular;
  font-size: rem(20);
  line-height: 1.55;
  border-radius: unset;
}
