// ==========================================================================
// Circles
// ==========================================================================

/*----------  Global  ----------*/

.circle {
  position: relative;
  padding: 0;

  border-radius: rem(25);
  color: $white;
  text-transform: uppercase;

  img {
    position: absolute;
    top: 50%; left: 50%;

    transform: translate(-50%, -50%);
  }
}

/*----------  Variante de taille  ----------*/

// petits cercles
.circle { width: rem(30); height: rem(30); }

// cercles moyens
.circle--medium { width: rem(40); height: rem(40); }

// grands cercles
.circle--large {
  width: rem(45); height: rem(45);

  img { width: rem(30); height: auto; }
}

// très grands cercles
.circle--extralarge { width: rem(50); height: rem(50); }


/*----------  Éléments  ----------*/

.circle__item {
  display: block;
  text-align: center;

  font-family: $compotes-bold;

  // cercles moyens
  &.circle--medium {
    font-size: rem(18);
    line-height: 2.25;
  }

  // cercles larges
  &.circle--large {
    font-size: rem(25);
    line-height: 1.8;
  }

  // cercles larges
  &.circle--extralarge {
    font-size: rem(25);
    line-height: 2.1;
  }
}


/*----------  Listes  ----------*/

// Liste ordonnée
.circle__list {
  counter-reset: my-awesome-counter;
  list-style: none;
  padding-inline-start: 0;

  .circle__item {

    margin: rem(15);
    counter-increment: my-awesome-counter;
    position: relative;

    &:before {
      content: counter(my-awesome-counter);
      position: absolute;
      left: 50%; top: 50%;

      transform: translate(-50%, -50%);
      border-radius: 50%;
      color: $white;
      font-family: $compotes-bold;
      font-size: rem(25);
      line-height: 1.8;
    }
  }
}
