.c-coming-soon {
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background: $white;

    &__img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}
