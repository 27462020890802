// ==========================================================================
// Home nouveauté
// ==========================================================================

.home-120 {
  &__title {
    margin: rem(0) rem(25) rem(0) rem(25);
    font-family: $compotes-bold;
    font-size: rem(50);
  }

  &__image-container {
    position: relative;
  }

  &__polaroid {
    margin-bottom: rem(20);
  }

  &__description {
    font-family: $barlow-regular;
    font-size: rem(20);
    line-height: rem(24);
    margin: 0 rem(25);
  }
}

@include media-breakpoint-up(md) {
   .home-120 {
     margin-bottom: 0;

     &__title {
       font-size: rem(60);
       line-height: rem(60);
     }

     &__polaroid {
       margin-bottom: 0;
     }

   }
 }

@include media-breakpoint-up(lg) {
  .home-120 {
    position: relative;

    &__persil {
      width: rem(268);
      height: rem(470);
      background-image: url("#{$path-img}/ingredients/persil-home.png");
      background-size: contain;
      background-repeat: no-repeat;
      position:absolute;
      left: rem(-185);
      top: rem(70);
      z-index: 1;
    }

    &__title {
      font-size: rem(100);
      line-height: rem(100);
    }

    &__parallax-description {
      position: relative;
      top: rem(-100);
    }

    &__description { margin: 0 rem(25) 0 0; }
  }
}
