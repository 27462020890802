// ==========================================================================
// Images
// ==========================================================================

.img {
  width: auto; //nécessaire pour les img produit de tailles variables
  object-fit: cover !important;
}

.img--contain {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.img--tiny { @include full-size($block-tiny-img); }

.img--edito { @include full-size($edito-tiny-img); }

.img--default { @include full-size($card-default-mobile); }

.img--large { @include full-size($card-default-mobile); }

.img--highlight { @include full-size($card-default-mobile); }

// taille spécifique image - Fiche recette
.img--xlarge { @include full-size($card-default-mobile); }


/*----------  Image avec bordure  ----------*/
.border {
  box-shadow: rem(-5) rem(-5) rem(16) rem(-5) rgba(34,34,34,0.4);

  &.border--thin { border: rem(6) solid $white !important; }
  &.border--thick { border: rem(10) solid $white !important; }
}

// pas d'ombre à l'image si bloc ombré dessous
.box-shadow {
  .border { box-shadow: none; }
}

// bloc edito ombre override
.block--edito.box-shadow {
  .border {
    box-shadow: rem(-5) rem(-5) rem(16) rem(-5) rgba(34,34,34,0.4);
  }
}

// ==========================================================================
// Media Queries
// ==========================================================================

@include media-breakpoint-up(sm) {

  .img--xlarge { @include full-size($card-default-desktop); }
}

@include media-breakpoint-up(md) {

  .img--default { @include full-size($card-default-desktop); }
  .img--large { @include full-size($card-default-desktop-large); }
  .img--highlight { @include full-size($card-recipe-highlight); }
  .img--xlarge { @include full-size($card-default-desktop-large); }
}

// breakpoint spécifique image - Fiche recette
@media (min-width: 1340px) {

  .img--xlarge { @include full-size($card-recipe-large); }
}

// ==========================================================================
// Images d'illustration
// ==========================================================================

$mache: "#{$path-img}/ingredients/jeune-pousse.png";
$mache2: "#{$path-img}/ingredients/jeune-pousse-3.png";
$persil: "#{$path-img}/ingredients/persil-home.png";
$tomates: "#{$path-img}/ingredients/tomates.png";
$myrtilles: "#{$path-img}/ingredients/myrtilles.png";
$coriandre: "#{$path-img}/ingredients/coriandre-2.png";

.img__illu {
    position: relative;

    &:before {
        content: '';
        position: absolute;

        background-repeat: no-repeat;
        background-color: transparent;
        z-index: 100;
    }

    &--mache {
        transform: rotate(-3deg);

        &:before {
            height: rem(150); width: rem(150);
            bottom: rem(-20); left: -50%;

            background-image: url($mache);
            background-size: cover;
            transform: translateX(-20%);
        }
    }

}

@include media-breakpoint-up(lg) {

    .img__illu {

        &--persil {
            &:before {
                width: rem(180); height: rem(470);

                background-image: url($persil);
                background-size: contain;
                background-position: center center;
                transform: translateX(-100%);
            }
        }

        &--myrtilles {
            &:before {
                bottom: rem(50); right: -45%;
                width: rem(385); height: rem(280);

                background-image: url($myrtilles);
                background-size: contain;
                background-position: center center;
                transform: scale(-1) rotate(-220deg) translate(-80%, 100%);
            }
        }

        &--mache-2 {

            &:before {
                height: rem(200); width: rem(215);
                top: -75%; right: 0;

                background-image: url($mache2);
                background-size: contain;
                transform: translate(50%, 50%);
            }
        }

    }
}

@include media-breakpoint-up(xl) {

    .img__illu {

        &--persil {
            &:before { transform: translateX(-50%); }
        }

        &--myrtilles {
            &:before { right: -29%; }
        }

        &--coriandre {
            &:before {
                top: 0; left: 0;
                width: rem(180); height: rem(180);

                background-image: url($coriandre);
                background-size: contain;
                transform: translate(-50%, 100%);
            }
        }
    }
}

@include media-breakpoint-up(xxl) {
    .img__illu {

        &--myrtilles {
            &:before { right: -35%; }
        }
        &--mache-2 {

            &:before { transform: translate(100%, 100%); }
        }
    }
}
