// ==========================================================================
// Custom block image
// ==========================================================================

.custom-block-image {
	margin-bottom: rem(40);

	&__legend {
		font-family: $barlow-regular;
		font-size: rem(14);
		line-height: rem(26);
		color: $black;
		padding-top: rem(10);
	}
}

@include media-breakpoint-up(md) {
	.custom-block-image {

	}
}