// ==========================================================================
// Header
// ==========================================================================

.header {
  left: 0; right: 0;

  background: $white;

  // Affiche la navigation
  &.is-show {
    display: block;
  }

  &.is-home {
    position: relative;
    border-bottom: none;
    background: transparent;
    min-height: rem(438);
    padding-bottom: rem(80);

    .header {
        &__image {
            width: 100%;
            height: 100%;
            background-size: cover;
            background-repeat: no-repeat;
            background-position: left;
            border-radius: 0% 0% 50% 50% / 0% 0% 3% 3%;
            position: absolute;
            z-index: -1;
        }
    }
  }

  &.is-header-transparent {
      position: relative;
      background: transparent;
      min-height: rem(610);
      .header {
          &__image {
              position: absolute;
              z-index: -1;
              top: 0;
              height: 100%;
              width: 100%;
              object-fit: cover;
              object-position: left;
              border-radius: 0% 0% 50% 50% / 0% 0% 10% 10%;
              @supports(clip-path: ellipse(110% 75% at 50% 25%)) {
                  clip-path: ellipse(110% 75% at 50% 25%);
                  border-radius: 0;
              }

          }
      }
  }

  &__presentation {
    display: flex;
    flex-direction: column;
    margin: rem(75) rem(20);
  }

  &__presentation-title {
    color: white;
    font-family: $barlow-semibold;
    margin-bottom: rem(0);
    font-size: rem(60);

    /* slabtext */
    p {
      font-family: $barlow-semibold;
      margin: 0;
    }
  }

  &__presentation-text {
    color: $white;
    font-size: rem(18);
    font-family: $barlow-regular;
  }

  &__presentation-btn {
    align-self: flex-start;
  }

  &__image {
    position: absolute;
    bottom: 0;
  }

  &__video-background {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;

    width: 100%;
    height: 100%;
    border-radius: 0% 0% 50% 50% / 0% 0% 10% 10%;

    @supports(clip-path: ellipse(110% 75% at 50% 25%)) {
      clip-path: ellipse(110% 75% at 50% 25%);
      border-radius: 0;
    }

    overflow: hidden;
  }

  &__video {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;

    margin: auto;

    opacity: 0;
    transition: opacity .5s;
    border-radius: 0% 0% 50% 50% / 0% 0% 10% 10%;


    &.active {
      opacity: 1;
    }
  }
}

.header-mobile {
  .header__container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: rem(13) rem(20);
  }

  .logo, .logo:visited { flex: 0 0 7.5rem; }

  // si menu de la home
  &.is-home { background: transparent; }

  &.is-active {
    background-color: $white;
  }
}

@include media-breakpoint-up(sm) {
  .header {
    &__presentation-title {
      width: rem(300);
    }
  }
}

@include media-breakpoint-up(md) {
  .header-top { display: none; }
  .header-mobile { display: none; }
  .header {
    display: block;

    &.is-home {
      height: rem(612);
       .header {
           &__image {
               border-radius: 0% 0% 50% 50% / 0% 0% 10% 10%;

               @supports(clip-path: ellipse(110% 75% at 50% 25%)) {
                   clip-path: ellipse(110% 75% at 50% 25%);
                   border-radius: 0;
               }
           }
       }
    }

    &__presentation {
      margin-left: rem(70);
    }

    &__presentation-text {
      margin-top: rem(20);
      font-size: rem(24);
      line-height: rem(26);
    }

    &__presentation-title {
      width: rem(400);
    }
  }
}
