// ==========================================================================
// Home nouveauté
// ==========================================================================

.home-completely-goat {
  &__title {
    margin: rem(0) rem(25) rem(20) 0;
    font-family: $compotes-bold;
    font-size: rem(50);
  }

  &__subtitle {
    font-size: rem(20);
    line-height: rem(26);
  }

  &__slate {
    background-image: url("#{$path-img}/ardoise-mobile.jpg");
    background-size: cover;
    padding: rem(40) rem(20);
  }

  &__slate-title {
    position: relative;
    margin-bottom: 55px;
    font-family: $coalhandluke;
    color: $white;
    text-transform: none;
    font-size: rem(32);
    line-height: rem(35);

      &:after {
          content: '';
          position: absolute;
          width: rem(250); height: rem(30);
          bottom: -50%; left: 0;

          background-image: url("#{$path-img}/trait-ardoise.svg");
          background-repeat: no-repeat;
          background-size: contain;
      }
  }

  &__slate-description {
    font-family: $coalhandluke;
    font-size: rem(28);
    line-height: rem(35);
    color: $white;
  }

  &__presentation {
    background-color: $white;
    background-image: url("#{$path-img}/fond_texture.png");
  }
}

@include media-breakpoint-up(md) {
  .home-completely-goat {
    position: relative;
    &__title {
      font-size: rem(60);
      line-height: rem(60);
      margin: 0;
    }

    &__subtitle {
        width: 75%;
    }

    &__slate {
      transform: rotate(-3deg);
      background-image: url("#{$path-img}/ardoise-variant.png");
      background-size: cover;
      padding: rem(60) rem(80);
      box-shadow: -8px 9px 5px 0px rgba(0,0,0,0.75);
    }

    &__slate-title {
      font-size: rem(40);
      line-height: rem(40);
      margin-bottom: rem(80);

      &:after { background-position-y: bottom; }
    }

    &__slate-description {
      font-size: rem(30);
      line-height: rem(35);

      br {
        margin-bottom: rem(30);
      }
    }

    &__parallax {
      width: rem(445);
      height: rem(379);
      position: absolute;
      left: rem(-300);
      bottom: rem(-100);
    }

    &__leg {
      position: absolute;
    }

    &__chive {
      position: absolute;
    }
  }
}

@include media-breakpoint-up(lg) {
  .home-completely-goat {
    position: relative;
    &__title {
      font-size: rem(100);
      line-height: rem(100);
    }

    &__leg {
      width: rem(477);
      height: rem(379);
      background-image: url("#{$path-img}/ingredients/patte-gauche.png");
      background-repeat: no-repeat;
      background-position-x: rem(-20);
    }

    &__chive {
      width: rem(600);
      height: rem(445);
      background-image: url("#{$path-img}/ingredients/ciboulette.png");
      background-size: cover;
      position: absolute;
      right: rem(-300);
      top: rem(-215);
      z-index: 1;
    }

    &__slate-title {
        &:after { background-position-y: unset; }
    }
  }
}

@include media-breakpoint-up(xl) {
    .home-completely-goat {

        &__slate-title {
            &:after { background-position-y: bottom; }
        }
    }
}
