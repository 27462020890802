// ==========================================================================
// Polaroid
// ==========================================================================

.polaroid {
  position: relative;

  &__faker {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
    border: rem(6) solid $white;
    box-shadow: rem(-5) rem(-5) rem(16) rem(-5) rgba(34,34,34,0.4);
    background-color: $white;
    background-size:cover;
    background-repeat: no-repeat;
  }

  &__image {
    border: rem(6) solid $white;
    box-shadow: rem(-5) rem(-5) rem(16) rem(-5) rgba(34,34,34,0.4);
  }

}
