// ==========================================================================
// Breadcrumbs
// ==========================================================================

.breadcrumb {
  justify-content: flex-end;
  margin-bottom: rem(10);
  padding: rem(20) 0;

  font-family: $barlow-regular;
  font-size: rem(16);
  line-height: 1.5;
  text-align: right;
  background: none;
}

.breadcrumb-item {

  + .breadcrumb-item:before {
    content: '//';
    color: $black;
  }

  a, a:visited {
      &:hover, &:focus { font-family: $barlow-regular; }
  }

  /* état actif du breadcrumb */
  &.active {
    a, a:visited {
      color: $primary;
      font-family: $barlow-medium;
    }
  }
}
