// ==========================================================================
// Tableau valeurs nutritionnelles
// ==========================================================================

.nutri-values__table {
  display: flex;
  flex-direction: column;
  margin-bottom: rem(25);

  font-family: $barlow-regular;
  font-size: rem(18);
  line-height: normal;

  /* titre */
  caption {
    order: 0;
    margin-bottom: rem(20);
  }

  .circle {
    font-family: $compotes-regular;
    text-transform: none;
  }

  tr {
    display: flex;
    justify-content: flex-end;

    // titres
    > th {
      margin-right: auto;
      font-family: $barlow-semibold;
      font-weight: normal;

      &.subvalue { font-family: $barlow-light-italic; }

      // 1ère colonne
      + td { flex: 0 0 rem(120); }
    }

    > th ~ td {
      display: flex;
      justify-content: center;

      text-align: center;
    }

    // 2e colonne
    > th + td + td { flex: 0 0 rem(55); }
  }

  /* légende */
  tfoot { order: 3; }

  .nutri-values__us-cell {
    line-height: 1;

    &--amount {
        margin-bottom: rem(5);
        margin-top: rem(40);
    }

    &--calories {
        font-size: rem(28);
    }

    td {
        font-weight: bold;
    }
  }
}

.nutri-values__head {
  order: 1;

  tr { margin-bottom: rem(20); }
}

.nutri-values__body {
  order: 2;

  // 1ère bulle "Energie"
  tr:first-of-type {
    td:first-of-type {
      .circle__item {
        width: auto;
        padding-left: rem(10); padding-right: rem(10);
        letter-spacing: rem(1);
      }
    }
  }

  tr { margin-bottom: rem(5); }

}

.nutri-values__legend {
  font-family: $barlow-italic;
  font-size: rem(16);
}

.nutri-values__no-info {
    display: block;
    background: $grey-2;
    border-radius: 50%;
    height: rem(8);
    width: rem(8);
    min-width: rem(8);
    margin: auto;
}

// ==========================================================================
// Media Queries
// ==========================================================================

@include media-breakpoint-up(lg) {

  .nutri-values__table {

    font-size: rem(20);
    line-height: 2.25;

    /* titre */
    caption { margin-bottom: 0; padding-bottom: 0; }

    tr {
      display: flex;

      > th { flex: 0 0 rem(160); }

      > th ~ td {
        flex: 0 0 rem(120);

        text-align: center;
      }

      // 2e colonne
      > th + td + td { flex: 0 0 rem(115); }
    }
  }

  .nutri-values__head {
    tr { margin-bottom: rem(10); }
  }

  .nutri-values__body {
    tr { margin-bottom: rem(10); }
  }

  .nutri-values__legend { line-height: 2.81; }
}

@include media-breakpoint-up(xl) {

    .nutri-values__table {
        tr {
            display: flex;
            justify-content: unset;

            > th {
                flex: 0 0 rem(280);
                margin-right: 0;
            }
        }
    }
}
