// ==========================================================================
// Sitemap page
// ==========================================================================

.sitemap {

    .sitemap__lvl-1 > li {
        padding-bottom: rem(10);
        list-style-type: disc;
        color: $primary;

        > a, > a:visited {
            font-family: $compotes-bold;
            font-size: rem(30);
            color: $primary;
            text-transform: uppercase;

            &:hover, &:focus { text-decoration: underline; }
        }

        > ul > li {

            > a, > a:visited {
                font-family: $barlow-semibold;
                padding-bottom: rem(5);

                &:hover, &:focus {
                    font-family: $barlow-semibold;
                    text-decoration: underline;
                }
            }
        }
    }
}
