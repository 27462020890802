// ==========================================================================
// SlabText
// ==========================================================================

.slabtexted .slabtext
{
  display: -moz-inline-box;
  display: inline-block;
  white-space: nowrap;
}
.slabtextinactive .slabtext
{
  display: inline;
  white-space: normal;
  font-size: 1em !important;
  letter-spacing: inherit !important;
  word-spacing: inherit !important;
  *letter-spacing: normal !important;
  *word-spacing: normal !important;
}
.slabtextdone .slabtext
{
  display: block;
}

.slabtext-linesize-0 {
  line-height: 0.8!important;
  margin-top: 2rem;
}
.slabtext-linesize-1 {
  line-height: 1!important;
}
.slabtext-linesize-2 {
  line-height: 1.2!important;
}
.slabtext-linesize-3 {
  line-height: 1.2!important;
}