// ==========================================================================
// Buttons CTA
// ==========================================================================

.cta-btns {
  display: inline-flex;
  justify-content: space-between;

  .btn { cursor: pointer; }
}

.cta-btn {

  &:not(:first-child) {
    margin-left: rem(24);
  }
}

/*----------  Variante de position ----------*/

// position verticale
.cta-v-container {
    float: right;
    transform: translateX(calc(100% + 50px));
}

.cta--v {

    flex-direction: column;

    .cta-btn {

      &:not(:first-child) {
        margin-left: 0;
        margin-top: rem(24);
    }
  }
}

@include media-breakpoint-up(xl) {

    // position verticale
    .cta-v-container { transform: translateX(calc(100% + 80px)); }
}
